import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const IdCardSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      sx={{ fill: "none" }}
      {...props}
    >
      <g opacity="0.5" clipPath="url(#clip0_65_2444)">
        <path
          d="M17.7083 2.5H2.29172C1.02753 2.5 0 3.52753 0 4.79172V15.2083C0 16.4725 1.02753 17.5 2.29172 17.5H17.7083C18.9725 17.5 20 16.4725 20 15.2083V4.79172C20 3.52753 18.9725 2.5 17.7083 2.5V2.5ZM6.25 5.83328C7.39838 5.83328 8.33328 6.76834 8.33328 7.91672C8.33328 9.06494 7.39838 10 6.25 10C5.10162 10 4.16672 9.06494 4.16672 7.91672C4.16672 6.76834 5.10162 5.83328 6.25 5.83328ZM10 13.5417C10 13.8867 9.72 14.1667 9.375 14.1667H3.125C2.78 14.1667 2.5 13.8867 2.5 13.5417V13.125C2.5 11.8608 3.52753 10.8333 4.79172 10.8333H7.70828C8.97247 10.8333 10 11.8608 10 13.125V13.5417ZM16.875 14.1667H12.2917C11.9467 14.1667 11.6667 13.8867 11.6667 13.5417C11.6667 13.1967 11.9467 12.9167 12.2917 12.9167H16.875C17.22 12.9167 17.5 13.1967 17.5 13.5417C17.5 13.8867 17.22 14.1667 16.875 14.1667ZM16.875 10.8333H12.2917C11.9467 10.8333 11.6667 10.5533 11.6667 10.2083C11.6667 9.86328 11.9467 9.58328 12.2917 9.58328H16.875C17.22 9.58328 17.5 9.86328 17.5 10.2083C17.5 10.5533 17.22 10.8333 16.875 10.8333ZM16.875 7.5H12.2917C11.9467 7.5 11.6667 7.22 11.6667 6.875C11.6667 6.53 11.9467 6.25 12.2917 6.25H16.875C17.22 6.25 17.5 6.53 17.5 6.875C17.5 7.22 17.22 7.5 16.875 7.5Z"
          fill="#403845"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_2444">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
