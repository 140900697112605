import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AdminPanelSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      sx={{ fill: "none" }}
      {...props}
    >
      <path
        d="M12 5.625C8.51067 5.625 5.67188 8.4638 5.67188 11.9531V12.6562H7.07812C7.46644 12.6562 7.78125 12.3414 7.78125 11.9531C7.78125 9.62691 9.67378 7.73438 12 7.73438C14.3262 7.73438 16.2188 9.62691 16.2188 11.9531C16.2188 12.3414 16.5336 12.6562 16.9219 12.6562H18.3281V11.9531C18.3281 8.4638 15.4893 5.625 12 5.625Z"
        fill="#403845"
      />
      <path
        d="M23.25 8.4375H21.9417C21.8173 8.08336 21.6757 7.73906 21.5179 7.40705L22.4408 6.48413C22.5727 6.35231 22.6468 6.17344 22.6468 5.98697C22.6468 5.8005 22.5728 5.62162 22.4408 5.48981L18.4634 1.51233C18.1888 1.23778 17.7436 1.23778 17.469 1.51233L16.546 2.4353C16.214 2.27747 15.8696 2.13581 15.5156 2.0115V0.703125C15.5156 0.314812 15.2008 0 14.8125 0H9.1875C8.79919 0 8.48438 0.314812 8.48438 0.703125V2.01145C8.13033 2.13577 7.78603 2.27742 7.45397 2.4352L6.53105 1.51228C6.39919 1.38042 6.22036 1.30631 6.03384 1.30631C5.84737 1.30631 5.66855 1.38038 5.53669 1.51228L1.5592 5.48977C1.42734 5.62158 1.35323 5.80045 1.35323 5.98692C1.35323 6.17339 1.42734 6.35227 1.5592 6.48408L2.48213 7.407C2.3243 7.73906 2.18269 8.08345 2.05837 8.43745H0.75C0.361687 8.43745 0.046875 8.75226 0.046875 9.14058V11.9531C0.046875 12.3414 0.361687 12.6562 0.75 12.6562H4.26562V11.9531C4.26562 7.68834 7.73527 4.2187 12 4.2187C16.2647 4.2187 19.7344 7.68834 19.7344 11.9531V12.6562H23.25C23.6383 12.6562 23.9531 12.3414 23.9531 11.9531V9.14058C23.9531 8.75231 23.6383 8.4375 23.25 8.4375Z"
        fill="#403845"
      />
      <path
        d="M6.08484 19.2725C5.41828 19.8794 4.53286 20.2499 3.5625 20.2499C2.59214 20.2499 1.70672 19.8794 1.04016 19.2725C0.4395 19.6968 0.046875 20.3961 0.046875 21.1874V23.2968C0.046875 23.6851 0.361687 23.9999 0.75 23.9999H6.375C6.76331 23.9999 7.07812 23.6851 7.07812 23.2968V21.1874C7.07812 20.3961 6.6855 19.6968 6.08484 19.2725Z"
        fill="#403845"
      />
      <path
        d="M3.5625 18.8438C4.85692 18.8438 5.90625 17.7944 5.90625 16.5C5.90625 15.2056 4.85692 14.1562 3.5625 14.1562C2.26808 14.1562 1.21875 15.2056 1.21875 16.5C1.21875 17.7944 2.26808 18.8438 3.5625 18.8438Z"
        fill="#403845"
      />
      <path
        d="M22.9598 19.2725C22.2933 19.8794 21.4079 20.2499 20.4375 20.2499C19.4671 20.2499 18.5817 19.8794 17.9152 19.2725C17.3145 19.6968 16.9219 20.3961 16.9219 21.1874V23.2968C16.9219 23.6851 17.2367 23.9999 17.625 23.9999H23.25C23.6383 23.9999 23.9531 23.6851 23.9531 23.2968V21.1874C23.9531 20.3961 23.5605 19.6968 22.9598 19.2725Z"
        fill="#403845"
      />
      <path
        d="M20.4375 18.8438C21.7319 18.8438 22.7812 17.7944 22.7812 16.5C22.7812 15.2056 21.7319 14.1562 20.4375 14.1562C19.1431 14.1562 18.0938 15.2056 18.0938 16.5C18.0938 17.7944 19.1431 18.8438 20.4375 18.8438Z"
        fill="#403845"
      />
      <path
        d="M14.5223 19.2725C13.8558 19.8794 12.9704 20.2499 12 20.2499C11.0296 20.2499 10.1442 19.8794 9.47766 19.2725C8.877 19.6968 8.48438 20.3961 8.48438 21.1874V23.2968C8.48438 23.6851 8.79919 23.9999 9.1875 23.9999H14.8125C15.2008 23.9999 15.5156 23.6851 15.5156 23.2968V21.1874C15.5156 20.3961 15.123 19.6968 14.5223 19.2725Z"
        fill="#403845"
      />
      <path
        d="M12 18.8438C13.2944 18.8438 14.3438 17.7944 14.3438 16.5C14.3438 15.2056 13.2944 14.1562 12 14.1562C10.7056 14.1562 9.65625 15.2056 9.65625 16.5C9.65625 17.7944 10.7056 18.8438 12 18.8438Z"
        fill="#403845"
      />
    </SvgIcon>
  );
};
