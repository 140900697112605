import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="245"
      height="72"
      viewBox="0 0 245 72"
      sx={{ fill: "none" }}
      {...props}
    >
      <path
        opacity="0.75"
        d="M0 69.6693C24.7648 69.6693 44.8688 54.5834 44.8688 36C44.8688 17.4167 24.7648 2.33081 0 2.33081V18.5651V69.6693Z"
        fill="#3FB56E"
      />
      <path
        opacity="0.75"
        d="M22.4695 69.6693C47.2343 69.6693 67.3383 54.5834 67.3383 36C67.3383 17.4167 47.2343 2.33081 22.4695 2.33081V18.5651V69.6693Z"
        fill="#F44336"
      />
      <path
        opacity="0.75"
        d="M44.8691 69.6693C69.6339 69.6693 89.738 54.5834 89.738 36C89.738 17.4167 69.6339 2.33081 44.8691 2.33081V18.5651V69.6693Z"
        fill="#FFA000"
      />
      <path
        d="M124.564 26.476C123.142 26.476 121.659 26.2921 120.114 25.9244C118.57 25.5567 117.111 25.0541 115.738 24.4168V20.9234C117.356 21.8059 118.852 22.4556 120.225 22.8723C121.622 23.2891 122.995 23.4974 124.343 23.4974C126.035 23.4974 127.347 23.1175 128.278 22.3575C129.21 21.5976 129.676 20.5557 129.676 19.2319C129.676 18.3248 129.418 17.5649 128.904 16.952C128.389 16.3391 127.739 15.8488 126.954 15.4811C126.17 15.1134 125.116 14.7089 123.792 14.2676C122.198 13.7283 120.911 13.2135 119.93 12.7232C118.974 12.2329 118.153 11.5342 117.466 10.6272C116.78 9.72012 116.437 8.54341 116.437 7.09704C116.437 4.93973 117.221 3.2237 118.79 1.94893C120.359 0.649642 122.468 0 125.116 0C127.69 0 130.056 0.551583 132.213 1.65475V5.03779C129.786 3.66496 127.42 2.97855 125.116 2.97855C123.424 2.97855 122.1 3.32176 121.144 4.00817C120.188 4.67007 119.71 5.58938 119.71 6.76609C119.71 7.57508 119.955 8.24923 120.445 8.78856C120.936 9.32788 121.536 9.76915 122.247 10.1124C122.958 10.4311 123.963 10.8233 125.263 11.2891C126.881 11.8284 128.205 12.3555 129.235 12.8703C130.264 13.3851 131.135 14.1328 131.846 15.1134C132.581 16.094 132.949 17.3565 132.949 18.9009C132.949 20.4208 132.606 21.7569 131.919 22.9091C131.233 24.0368 130.252 24.9193 128.977 25.5567C127.702 26.1696 126.231 26.476 124.564 26.476Z"
        fill="#FF7F50"
      />
      <path
        d="M151.324 26.476C148.847 26.476 146.739 25.9244 144.998 24.8212C143.282 23.7181 141.983 22.1736 141.1 20.1879C140.242 18.2022 139.813 15.8856 139.813 13.238C139.813 10.5904 140.242 8.27375 141.1 6.28805C141.983 4.30235 143.282 2.75792 144.998 1.65475C146.739 0.551583 148.847 0 151.324 0C152.672 0 153.971 0.171604 155.222 0.514811C156.472 0.833502 157.612 1.31154 158.642 1.94893V5.62615H158.495C157.686 4.84168 156.668 4.21655 155.442 3.75077C154.217 3.26047 152.88 3.01532 151.434 3.01532C148.639 3.01532 146.58 3.92237 145.256 5.73646C143.956 7.55056 143.307 10.0511 143.307 13.238C143.307 16.4494 143.956 18.9622 145.256 20.7763C146.58 22.5904 148.627 23.4974 151.397 23.4974C152.795 23.4974 154.106 23.2646 155.332 22.7988C156.582 22.333 157.637 21.6833 158.495 20.8498H158.642V24.5271C157.612 25.1645 156.472 25.6548 155.222 25.998C153.971 26.3167 152.672 26.476 151.324 26.476Z"
        fill="#FF7F50"
      />
      <path
        d="M182.242 19.2686H169.922L167.311 26.0347H164.075L173.93 0.441265H178.417L188.273 26.0347H184.853L182.242 19.2686ZM181.175 16.5107L176.1 3.27273L170.988 16.5107H181.175Z"
        fill="#FF7F50"
      />
      <path
        d="M214.612 0.441265V26.0347H211.376L197.622 6.28805V26.0347H194.386V0.441265H197.622L211.376 20.1879V0.441265H214.612Z"
        fill="#FF7F50"
      />
      <path
        d="M244.025 0.441265V26.0347H240.789L227.035 6.28805V26.0347H223.799V0.441265H227.035L240.789 20.1879V0.441265H244.025Z"
        fill="#FF7F50"
      />
      <path
        d="M118.949 37.8754H130.227C134.934 37.8754 138.448 38.8396 140.769 40.7681C143.09 42.6639 144.251 45.4259 144.251 49.0541C144.251 52.715 143.107 55.5587 140.818 57.5853C138.53 59.6119 135.196 60.6251 130.816 60.6251H125.422V72H118.949V37.8754ZM130.276 54.9867C132.728 54.9867 134.526 54.5455 135.67 53.6629C136.847 52.7804 137.435 51.2932 137.435 49.2012C137.435 47.1747 136.863 45.7365 135.719 44.8866C134.575 44.0368 132.744 43.6119 130.227 43.6119H125.422V54.9867H130.276Z"
        fill="#FFB72B"
      />
      <path
        d="M190.84 54.2513C192.606 55.0031 193.946 56.0654 194.861 57.4382C195.776 58.811 196.234 60.478 196.234 62.4392C196.234 65.5444 195.106 67.9142 192.851 69.5485C190.595 71.1828 187.326 72 183.044 72H170.442V37.8754H183.632C187.326 37.8754 190.17 38.6599 192.164 40.2288C194.158 41.7651 195.155 43.9714 195.155 46.8478C195.155 50.2799 193.717 52.7477 190.84 54.2513ZM176.915 43.5628V51.7017H183.044C184.809 51.7017 186.133 51.3422 187.016 50.6231C187.898 49.904 188.34 48.858 188.34 47.4852C188.34 45.9816 187.947 44.952 187.163 44.3963C186.411 43.8407 185.054 43.5628 183.093 43.5628H176.915ZM183.044 66.2635C185.365 66.2635 186.999 65.9367 187.947 65.2829C188.928 64.5965 189.418 63.4362 189.418 61.8018C189.418 60.2656 188.895 59.1379 187.849 58.4188C186.836 57.6997 185.251 57.3401 183.093 57.3401H176.915V66.2635H183.044Z"
        fill="#FFB72B"
      />
      <path
        d="M223.899 37.8754H230.371V66.3126H244.738V72H223.899V37.8754Z"
        fill="#FFB72B"
      />
    </SvgIcon>
  );
};
