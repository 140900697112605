import { FC, useCallback } from "react";
import { useState } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { humanFileSize } from "src/utils/humanReadableFileSizeUtils";
import { DownloadSvg } from "src/components/atoms/svg/DownloadSvg";
import { ShareSvg } from "src/components/atoms/svg/ShareSvg";
import { RenameSvg } from "src/components/atoms/svg/RenameSvg";
import { BinSvg } from "src/components/atoms/svg/BinSvg";
import { PdfSvg } from "src/components/atoms/svg/PdfSvg";
import {
  SplitPdfRo,
  TaskRo,
  useTasksControllerDeleteMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { RenameFileDialog } from "./dialogs/RenameFileDialog";
import { useLazyTasksControllerGetSplittedPdfUrlQuery } from "src/app/services/api";

type HistoryTableBodyNestedRowPropsType = {
  row: SplitPdfRo;
  enhancedCreatedAt: string;
  taskState: TaskRo["taskState"];
};

export const HistoryTableBodyNestedRow: FC<
  HistoryTableBodyNestedRowPropsType
> = ({ row: { id, name, fileSize }, enhancedCreatedAt, taskState }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const { t } = useTranslation();

  const openDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);

  const [getUrl, { isLoading: getUrlLoading }] =
    useLazyTasksControllerGetSplittedPdfUrlQuery();

  const [deleteFile, { isLoading: deleteFileLoading }] =
    useTasksControllerDeleteMutation();

  const closePopover = () => setAnchorEl(null);

  const downloadFile = useCallback((url: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.click();
    toast.success(t("download.success"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popoverListItems = [
    {
      title: t("download"),
      icon: (
        <DownloadSvg
          sx={{
            fontSize: 19,
            "& > path": { fill: ({ palette }) => palette.customColor.color11 },
          }}
        />
      ),
      onClick: () => {
        getUrl({ id })
          .unwrap()
          .then((res) => res.url && downloadFile(res.url))
          .finally(closePopover);
      },
      isLoading: getUrlLoading,
    },
    {
      title: t("share"),
      icon: (
        <ShareSvg
          sx={{
            fontSize: 16,
            "& > path": { fill: ({ palette }) => palette.customColor.color11 },
          }}
        />
      ),
      onClick: () => {
        getUrl({ id })
          .unwrap()
          .then((res) => {
            if (res.url) {
              navigator.clipboard.writeText(res.url);
              toast.success(t("copy.success"));
            }
          })
          .finally(closePopover);
      },
    },
    {
      title: t("rename"),
      icon: <RenameSvg sx={{ fontSize: 16 }} />,
      onClick: openDialog,
    },
    {
      title: t("delete"),
      icon: (
        <BinSvg
          sx={{
            fontSize: 16,
            "& > path": { fill: ({ palette }) => palette.customColor.color11 },
          }}
        />
      ),
      onClick: () => {
        deleteFile({ idDto: { id } })
          .unwrap()
          .then(() => toast.success(t("taskSuccessfullyDeleted")))
          .finally(() => closePopover());
      },
      isLoading: deleteFileLoading,
    },
  ];

  const enhancedFileSize = humanFileSize(fileSize);

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell
          align="left"
          scope="row"
          sx={{
            width: { xs: 305, md: 360, lg: 510 },
          }}
        >
          <Tooltip title={name} arrow>
            <Stack direction="row" alignItems="left" gap={1}>
              <PdfSvg />
              <Typography
                fontSize={14}
                maxWidth={{ md: 100, lg: 150, xl: 300 }}
                textOverflow="ellipsis"
                noWrap
              >
                {name}
              </Typography>
            </Stack>
          </Tooltip>
        </TableCell>
        <TableCell align="left">
          <Typography fontSize={14} noWrap>
            {enhancedCreatedAt}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography fontSize={14} noWrap>
            {enhancedFileSize}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: taskState === "READY" ? green[500] : red[500],
            fontWeight: taskState === "READY" ? 500 : undefined,
          }}
        >
          {taskState ? t(taskState) : ""}
        </TableCell>
        <TableCell align="left" sx={{ width: 50 }}>
          <IconButton
            aria-describedby="more"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHorizIcon />
          </IconButton>
          <Popover
            elevation={1}
            id="more"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ p: 2 }}
            PaperProps={{
              sx: {
                borderRadius: 0,
                boxShadow: "0 4px 6px 0 rgba(0,0,0,0.15)",
                py: 1.5,
              },
            }}
          >
            <List>
              {popoverListItems.map(
                ({ onClick, icon, title, isLoading }, index) => (
                  <ListItemButton
                    onClick={onClick}
                    key={index}
                    sx={{ px: 3.1, py: 1.3 }}
                  >
                    {isLoading ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", minWidth: 102, py: 0.5 }}
                      >
                        <CircularProgress size={21} />
                      </Stack>
                    ) : (
                      <>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={title} />
                      </>
                    )}
                  </ListItemButton>
                )
              )}
            </List>
          </Popover>
        </TableCell>
      </TableRow>
      {showDialog && (
        <RenameFileDialog
          dialogProp={{
            open: true,
            onClose: closeDialog,
          }}
          taskId={id}
          oldName={name}
        />
      )}
    </>
  );
};
