import type { FC } from "react";
import { Box, Stack } from "@mui/material";
import { LanguageSwitcher } from "../organisms/layout/mainTemplate/navbar/LanguageSwitcher";
import { LogoSvg } from "../atoms/svg/LogoSvg";

type LoginTemplatePropsType = {
  children: any;
  minHeight?: number | string;
};

const LoginTemplate: FC<LoginTemplatePropsType> = ({ children, minHeight }) => {
  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100vh",
          position: "relative",
          minHeight,
          minWidth: "300px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: { xs: 2.5, sm: 5 },
            top: { xs: 2.5, sm: 5 },
          }}
        >
          <LanguageSwitcher />
        </Box>
        <LogoSvg
          sx={{
            width: { xs: 225 },
            height: { xs: 52 },
            mb: { xs: 2 },
          }}
        />
        <Box
          sx={{
            overflow: "hidden",
            minWidth: { xs: "100%", sm: 450 },
            maxWidth: { xs: "100%", sm: 450 },
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {children}
        </Box>
      </Stack>
    </>
  );
};

export default LoginTemplate;
