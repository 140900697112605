import type { FC } from "react";
import { MainTemplate } from "src/components/templates/MainTemplate";
import { UploadFileContextProvider } from "src/components/context/UploadFileContext";
import { Main } from "src/components/organisms/splitting/Main";

type HomePropsType = {};

export const Home: FC<HomePropsType> = () => {
  return (
    <MainTemplate>
      <UploadFileContextProvider>
        <Main />
      </UploadFileContextProvider>
    </MainTemplate>
  );
};
