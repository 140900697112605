import type { FC } from "react";
import { MainTemplate } from "src/components/templates/MainTemplate";
import { SettingsTemplate } from "src/components/templates/SettingsTemplate";
import { useUsersControllerFindQuery } from "src/app/services/api.generated";
import { AdminPanelTable } from "src/components/organisms/adminPanel/usersTable/AdminPanelTable";
import { useAppSelector } from "src/app/reduxCustomHooks";

export const AdminPanel: FC = () => {
  const { data, isLoading } = useUsersControllerFindQuery({});

  const ownUser = useAppSelector((state) => state.auth.user);

  const enhancedData = data?.users.filter(
    (user) => user.email !== ownUser!.email
  );

  return (
    <MainTemplate>
      <SettingsTemplate>
        <AdminPanelTable data={enhancedData} isLoading={isLoading} />
      </SettingsTemplate>
    </MainTemplate>
  );
};
