import type { FC, ChangeEvent, MouseEventHandler } from "react";
import { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UploadFileContext } from "../context/UploadFileContext";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { axiosGetFile } from "src/app/services/otherAPIs";

type GetFileUrlDialogPropsType = {
  onClose: DialogProps["onClose"];
};

export const GetFileUrlDialog: FC<GetFileUrlDialogPropsType> = ({
  onClose,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(),
    { pathname } = useLocation(),
    navigate = useNavigate();

  const inputChangeHandler = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setInputValue(value);

  const { setFile, setAppStep, resetApp, appStep } =
    useContext(UploadFileContext);

  const cancelClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClose && onClose(e, "escapeKeyDown");
  };

  const submitHandler: MouseEventHandler<HTMLButtonElement> = () => {
    setLoading(true);
    axiosGetFile(inputValue)
      .then(({ data }) => {
        const enhancedFile = new File([data], data?.name || "uploadedPdf.pdf");
        if (pathname === "/") {
          appStep !== 0 && resetApp();
          setFile(enhancedFile);
          setAppStep(1);
        } else {
          navigate("/", { state: { file: enhancedFile } });
        }
      })
      .catch((err) => {
        toast.error(t("fetch.catch.universalMessage"));
        err && console.log("Error", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle align="center">{t("getFileUrlDialog.title")}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: 2 }}
          label="URL"
          fullWidth
          value={inputValue}
          onChange={inputChangeHandler}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={loading}
          disabled={!inputValue}
          size="large"
          onClick={(e) => submitHandler(e)}
          variant="contained"
          color="primary"
          fullWidth
        >
          {t("submit")}
        </LoadingButton>
        <Button
          size="large"
          fullWidth
          variant="outlined"
          onClick={(e) => cancelClickHandler(e)}
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
