import { FC, Dispatch, SetStateAction, useContext } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Stack,
  Button,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import { Link } from "react-router-dom";
import { UploadFileButton } from "src/components/atoms/UploadFileButton";

type HistoryTableToolbarPropsType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const HistoryTableToolbar: FC<HistoryTableToolbarPropsType> = ({
  search,
  setSearch,
}) => {
  const { t } = useTranslation(),
    navigate = useNavigate(),
    { pathname } = useLocation();

  const isHomePage = pathname === "/";

  const { resetApp } = useContext(UploadFileContext);

  const addNewFileBtnClickHandler = () => {
    resetApp();
    navigate("/");
  };

  return (
    <Toolbar
      sx={{
        py: 3,
      }}
      component={Stack}
      gap={1}
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "center", sm: "space-between" }}>
      <Stack direction='row' alignItems='center' columnGap={1}>
        <Typography fontSize={30} fontWeight={700}>
          {t("history")}
        </Typography>
        {isHomePage && (
          <Link to='/history' style={{ textDecoration: "none" }}>
            <Button sx={{ mb: -1 }}>
              <Typography
                fontSize={16}
                fontWeight={500}
                color='rgba(0,0,0,0.35)'>
                {t("seeMore")}
              </Typography>
            </Button>
          </Link>
        )}
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        gap={2}
        sx={{ width: { xs: "100%", sm: "auto" } }}>
        {!isHomePage && (
          <TextField
            sx={{ width: { xs: "100%", sm: 330 } }}
            size='small'
            placeholder={t("searchByFileName")}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
          />
        )}
        <UploadFileButton />
        {/* <Button
          onClick={addNewFileBtnClickHandler}
          variant="contained"
          sx={{ fontSize: "16px !important" }}
        >
          {t("uploadNewFile")}
        </Button> */}
      </Stack>
    </Toolbar>
  );
};
