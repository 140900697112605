import type { FC, MouseEvent } from "react";
import { useState, useContext } from "react";
import {
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  Container,
  Button,
  Stack,
  IconButton,
  Avatar,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/app/reduxCustomHooks";
import { LogoSvg } from "src/components/atoms/svg/LogoSvg";
import { LogoIconSvg } from "src/components/atoms/svg/LogoIconSvg";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "./navbar/LanguageSwitcher";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import { ProfileSvg } from "src/components/atoms/svg/ProfileSvg";
import { HistorySvg } from "src/components/atoms/svg/HistorySvg";
import { LogoutSvg } from "src/components/atoms/svg/LogoutSvg";
import { stringAvatar } from "src/utils/avatar";
import { logoutAction } from "src/app/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Header: FC = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch(),
    { t } = useTranslation(),
    navigate = useNavigate();

  const { appStep } = useContext(UploadFileContext);

  const settings = [
    {
      icon: <ProfileSvg />,
      text: t("viewProfile"),
      onClick: () => navigate("/profile"),
    },
    {
      icon: <HistorySvg />,
      text: t("history"),
      onClick: () => navigate("/history"),
    },
    {
      icon: <LogoutSvg />,
      text: t("logout"),
      onClick: () => {
        dispatch(logoutAction());
        toast.success(t("youAreSuccessfullyLoggedOut"));
      },
    },
  ];

  const { username, name } = useAppSelector((store) => ({
    username: store.auth.user?.username,
    name: store.auth.user?.name,
  }));

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorElUser((prevState) => (prevState ? null : event.currentTarget));

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const logoClickHandler = () => {
    navigate("/reset");
  };

  return (
    <AppBar
      sx={{
        bgcolor: "white",
        boxShadow: "0 4px 4px 0 rgba(0,0,0,.05)",
        zIndex: 5,
      }}
    >
      <Container maxWidth={appStep === 1 ? false : "lg"}>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box onClick={logoClickHandler} sx={{ cursor: "pointer" }}>
            <LogoSvg
              sx={{
                width: 130,
                height: 38,
                display: { xs: "none", md: "flex" },
              }}
            />
            <LogoIconSvg
              sx={{
                width: 38,
                height: 38,
                display: { xs: "flex", md: "none" },
              }}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            columnGap={{ xs: 0, sm: 1, md: 3 }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  fontSize: "20px",
                  color: ({ palette }) => palette.customColor.color3,
                }}
              >
                {t("pricing")}
              </Button>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  fontSize: "20px",
                  color: ({ palette }) => palette.customColor.color3,
                }}
              >
                {t("help")}
              </Button>
            </Link>
            <div
              style={{
                borderLeft: "1px solid rgba(64,56,69,0.2)",
                borderRight: "1px solid rgba(64,56,69,0.2)",
              }}
            >
              <LanguageSwitcher />
            </div>
            <Button
              onClick={handleOpenUserMenu}
              startIcon={
                <AccountCircleIcon
                  fontSize="large"
                  sx={{
                    fontSize: "32px !important",
                    "& path": {
                      fill: ({ palette }) => palette.customColor.color8,
                    },
                  }}
                />
              }
              endIcon={
                <KeyboardArrowDownIcon
                  fontSize="large"
                  sx={{
                    "& path": {
                      fill: ({ palette }) => palette.customColor.color3,
                    },
                  }}
                />
              }
              sx={{
                color: ({ palette }) => palette.customColor.color3,
                display: { xs: "none", sm: "flex" },
              }}
            >
              <Typography
                noWrap
                textOverflow="ellipsis"
                overflow="hidden"
                maxWidth={{ sm: 100, md: 150, lg: 200 }}
              >
                {username}
              </Typography>
            </Button>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              <AccountCircleIcon
                fontSize="large"
                sx={{
                  "& path": {
                    fill: ({ palette }) => palette.customColor.color8,
                  },
                }}
              />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  minWidth: 250,
                  borderRadius: 0,
                  py: 2,
                  mt: 0.5,
                  boxShadow: "0 4px 6px rgba(0,0,0,0.16)",
                },
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem sx={{ mb: 1 }}>
                <Stack direction="row" columnGap={1} alignItems="center">
                  <Avatar
                    sx={{
                      width: 60,
                      height: 60,
                      textTransform: "uppercase",
                      ...stringAvatar(name || "admin").sx,
                    }}
                    children={stringAvatar(name || "admin").children}
                  />
                  <Stack>
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {name}
                    </Typography>
                    <Typography>@{username}</Typography>
                  </Stack>
                </Stack>
              </MenuItem>
              {settings.map(({ icon, text, onClick }, index) => (
                <MenuItem
                  key={index}
                  onClick={onClick}
                  sx={{
                    borderBottom: index === 1 ? "1px solid #eee" : "none",
                    mx: 1,
                    py: 1.5,
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
