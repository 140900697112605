import type { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const SuspendSvg: FC<SvgIconProps> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{ fill: "none" }}
      {...props}
    >
      <path
        d="M8 0C3.58171 0 0 3.58171 0 8C0 12.4183 3.58171 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58171 12.4183 0 8 0ZM4.85432 3.23139C5.78771 2.61511 6.8815 2.28629 8 2.28571C9.12011 2.28454 10.2155 2.61457 11.1486 3.23429L3.23429 11.1486C1.49539 8.51493 2.22068 4.97032 4.85432 3.23139ZM11.1457 12.7686C10.2123 13.3849 9.11854 13.7137 8 13.7143C6.87989 13.7155 5.78446 13.3854 4.85143 12.7657L12.7657 4.85143C14.5046 7.48504 13.7793 11.0297 11.1457 12.7686Z"
        fill={theme.palette.customColor.color11}
      />
    </SvgIcon>
  );
};
