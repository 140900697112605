import { AxiosRequestConfig } from "axios";
import {
  api as generatedApi,
  MediaControllerUploadDocumentApiArg,
  MediaControllerUploadDocumentApiResponse,
} from "./api.generated";

export const api = generatedApi
  .enhanceEndpoints({
    addTagTypes: ["User", "Task"],
    endpoints: {
      // =========================== USER =========================== //
      usersControllerFind: {
        providesTags: (result) =>
          result
            ? [
                ...result.users.map(({ username }) => ({
                  type: "User" as const,
                  id: username,
                })),
                "User",
              ]
            : ["User"],
      },
      usersControllerCreate: {
        invalidatesTags: (result, error, { userCreateDto: { username } }) => [
          "User",
        ],
      },
      usersControllerSuspend: {
        invalidatesTags: (result, error, { usernameDto: { username } }) => [
          { type: "User", id: username },
        ],
      },
      usersControllerActivate: {
        invalidatesTags: (result, error, { usernameDto: { username } }) => [
          { type: "User", id: username },
        ],
      },
      usersControllerDelete: {
        invalidatesTags: (result, error, { usernameDto: { username } }) => [
          { type: "User", id: username },
        ],
      },
      // =========================== TASK =========================== //
      tasksControllerFindAll: {
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Task" as const,
                  id,
                })),
                "Task",
              ]
            : ["Task"],
      },
      tasksControllerDelete: {
        invalidatesTags: (result, error, { idDto: { id } }) => [
          { type: "Task", id },
        ],
      },
      tasksControllerRename: {
        invalidatesTags: () => ["Task"],
      },
      tasksControllerDeleteMany: {
        invalidatesTags: () => ["Task"],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      customMediaControllerUploadDocument: build.mutation<
        MediaControllerUploadDocumentApiResponse,
        MediaControllerUploadDocumentApiArg & {
          abortController?: AbortController;
          onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => {
          const formData = new FormData();
          formData.append("pdfsRanges", JSON.stringify(body.pdfsRanges));
          formData.append("file", body.file);

          return {
            url: `/api/v1/media`,
            method: "POST",
            body: formData,
            abortController,
            onUploadProgress,
          };
        },
      }),
    }),
  });

export const {
  useCustomMediaControllerUploadDocumentMutation,
  useLazyTasksControllerGetSplittedPdfUrlQuery,
  useLazyTasksControllerZipSplitTasksQuery,
} = api;
