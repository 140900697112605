import { createSlice } from "@reduxjs/toolkit";
import { UserRo } from "../services/api.generated";
import { logoutAction } from "./authSlice";

type appSettingsSliceType = {
  selectedUser: UserRo | null;
};

const initialState: appSettingsSliceType = {
  selectedUser: null,
};

const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }: { payload: UserRo | null }) => {
      state.selectedUser = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(logoutAction, () => initialState);
  },
});

export const { setSelectedUser: setSelectedUserAction } =
  appSettingsSlice.actions;

export default appSettingsSlice.reducer;
