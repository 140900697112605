import type { FC, MouseEvent, ChangeEvent } from "react";
import { useMemo, useState } from "react";
import { Table, TableContainer, TablePagination, Box } from "@mui/material";
import { HistoryTableHeader } from "./HistoryTableHeader";
import { HistoryTableBody } from "./historyTableBody/HistoryTableBody";
import { HistoryTableToolbar } from "./HistoryTableToolbar";
import { liveSearch } from "src/utils/liveSearchUtils";
import { TaskRo } from "src/app/services/api.generated";

export type tableOrderType = "asc" | "desc";

type HistoryTablePropsType = {
  data?: TaskRo[];
  isLoading: boolean;
  pagination?: boolean;
  sorting?: boolean;
};

export const HistoryTable: FC<HistoryTablePropsType> = ({
  data = [],
  isLoading,
  pagination = true,
  sorting = true,
}) => {
  const [order, setOrder] = useState<tableOrderType>("desc");
  const [orderBy, setOrderBy] = useState<keyof TaskRo>("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState<string>("");

  const rows = useMemo(() => {
    return liveSearch({
      originalArray: data,
      inputValue: search,
      searchItem: "name",
    });
  }, [data, search]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof TaskRo
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) =>
    setPage(newPage);

  const handleChangeRowsPerPage = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  return (
    <>
      <HistoryTableToolbar search={search} setSearch={setSearch} />
      <Box
        sx={{
          boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
          borderRadius: 0,
        }}>
        <TableContainer>
          <Table stickyHeader>
            <HistoryTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              sorting={sorting}
            />
            <HistoryTableBody
              isLoading={isLoading}
              rows={rows}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </>
  );
};
