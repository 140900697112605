import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FolderSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{
        fill: "none",
      }}
      {...props}
    >
      <path
        d="M13.8618 3.60986H13.057V6.02435H14.6667V4.41469C14.6667 3.97016 14.3064 3.60986 13.8618 3.60986Z"
        fill="#FFA436"
      />
      <path
        d="M13.8618 4.41449L11.4473 7.6338H1.33331V2.80483C1.33331 2.3603 1.69361 2 2.13814 2H6.05685C6.2704 2 6.4751 2.08478 6.62614 2.23581L7.99998 3.60966H13.057C13.5015 3.60966 13.8618 3.96995 13.8618 4.41449Z"
        fill="#FFBC2B"
      />
      <path
        d="M13.057 4.41455L11.4473 6.42662H13.8618V4.41455H13.057Z"
        fill="#8AE7FF"
      />
      <path
        d="M13.057 4.41455V8.4387L2.13812 5.21938V4.41455H13.057Z"
        fill="#BDF2FF"
      />
      <path
        d="M2.13812 5.21924H9.94309V7.63373H2.13812V5.21924Z"
        fill="white"
      />
      <path
        d="M13.8618 5.21924L13.057 6.02407V14.126H13.8618C14.3064 14.126 14.6667 13.7657 14.6667 13.3212V6.02407C14.6667 5.57953 14.3064 5.21924 13.8618 5.21924Z"
        fill="#FFBC2B"
      />
      <path
        d="M13.8618 5.21924V13.3212C13.8618 13.7657 13.5015 14.126 13.057 14.126H2.13814C1.69361 14.126 1.33331 13.7657 1.33331 13.3212V7.63373C1.33331 7.18919 1.69361 6.8289 2.13814 6.8289H7.99998L9.37382 5.45505C9.52486 5.30401 9.72956 5.21924 9.9431 5.21924H13.8618Z"
        fill="#FFCF66"
      />
    </SvgIcon>
  );
};
