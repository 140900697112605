import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CircularSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7335 2.84375C7.12725 2.84375 2.57725 7.39538 2.57725 13C2.57725 16.4336 4.2835 19.4707 6.89488 21.3086C7.44413 21.697 8.20463 21.5637 8.593 21.0145C8.97975 20.4636 8.84812 19.7031 8.29725 19.3164C6.31313 17.9189 5.01475 15.6097 5.01475 13C5.01475 8.73925 8.47275 5.28125 12.7335 5.28125C13.4063 5.28125 13.9523 4.73525 13.9523 4.0625C13.9523 3.38975 13.4063 2.84375 12.7335 2.84375Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3867 23.135C18.6875 22.797 22.8897 18.3851 22.8897 12.9999C22.8897 9.90588 21.502 7.13201 19.3164 5.26813C18.8045 4.83263 18.0342 4.89276 17.5987 5.40463C17.1616 5.91651 17.2234 6.68675 17.7352 7.12388C19.3976 8.53925 20.4522 10.6469 20.4522 12.9999C20.4522 17.0933 17.2591 20.4456 13.2307 20.7024C12.5596 20.7463 12.0494 21.3248 12.0932 21.9959C12.1355 22.6686 12.7156 23.1789 13.3867 23.135Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.204 4.46875H17.6085C16.9357 4.46875 16.3897 5.01475 16.3897 5.6875V10.283C16.3897 10.9557 16.9357 11.5017 17.6085 11.5017C18.2812 11.5017 18.8272 10.9557 18.8272 10.283V6.90625H22.204C22.8767 6.90625 23.4227 6.36025 23.4227 5.6875C23.4227 5.01475 22.8767 4.46875 22.204 4.46875Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.796 22.3438H8.3915C9.06425 22.3438 9.61025 21.7978 9.61025 21.125V16.5295C9.61025 15.8568 9.06425 15.3108 8.3915 15.3108C7.71875 15.3108 7.17275 15.8568 7.17275 16.5295V19.9063H3.796C3.12325 19.9063 2.57725 20.4523 2.57725 21.125C2.57725 21.7978 3.12325 22.3438 3.796 22.3438Z'
      />
    </SvgIcon>
  );
};
