import type { FC, Dispatch, SetStateAction } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Stack,
  Button,
} from "@mui/material";
import { Add, Search as SearchIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type AdminPanelTableToolbarPropsType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const AdminPanelTableToolbar: FC<AdminPanelTableToolbarPropsType> = ({
  search,
  setSearch,
}) => {
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        py: 3,
      }}
      component={Stack}
      gap={1}
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "center", sm: "space-between" }}
    >
      <Typography fontSize={30} fontWeight={700}>
        {t("adminPanel")}
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
        <TextField
          sx={{ width: "100%", maxWidth: 300 }}
          size="small"
          placeholder={t("searchByUsername")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
        />
        <Link to="/addUser" style={{ textDecoration: "none", width: 280 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{ fontSize: "16px !important" }}
            startIcon={<Add />}
          >
            {t("addUser")}
          </Button>
        </Link>
      </Stack>
    </Toolbar>
  );
};
