import { PageRangeDto } from "src/app/services/api.generated";
import {
  PagesPdfDataType,
  PagesType,
} from "src/components/context/UploadFileContext";

export const rangeToPages = ({ from, to }: PageRangeDto): number[] => {
  return [...Array(to - from + 1)].map((_, index) => index + from);
};

export const rangesToPages = (ranges: PageRangeDto[]): number[] =>
  ranges.reduce(
    (mergedRange, range) => mergedRange.concat(rangeToPages(range)),
    [] as number[]
  );

export const rangesToString = (ranges: PageRangeDto[]): string =>
  ranges.reduce(
    (res, range) =>
      (res ? res + "," : "") +
      (range.from === range.to
        ? range.from + 1
        : `${range.from + 1}-${range.to + 1}`),
    ""
  );

export const getPagesPdfData = (pages: PagesType[]): PagesPdfDataType[] => {
  let pdfIndex = 0;
  let cutUsed = false;
  return pages.map(({ isSelected, cutState }) => {
    const res = { pdfIndex: isSelected ? pdfIndex : undefined, cutState };
    if (isSelected) {
      cutUsed = true;
    }
    if (cutState && cutUsed) {
      pdfIndex++;
      cutUsed = false;
    }
    return res;
  });
};

export const pagesToRange = (pages: PagesType[]): PageRangeDto[][] => {
  const newPdfsRanges: PageRangeDto[][] = [];
  let seeCut = true;
  let from = -1;
  for (let i = 0; i < pages.length; i++) {
    from = from === -1 && pages[i].isSelected ? i : from;
    const to = !pages[i].isSelected
      ? i - 1
      : pages[i].cutState || i === pages.length - 1
      ? i
      : -1;
    if (from > -1 && to > -1) {
      if (seeCut) {
        newPdfsRanges.push([{ from, to }]);
        seeCut = false;
      } else {
        newPdfsRanges[newPdfsRanges.length - 1].push({ from, to });
      }
      from = -1;
    }
    if (pages[i].cutState) {
      seeCut = true;
    }
  }
  return newPdfsRanges;
};
