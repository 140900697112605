import type { FC, MouseEvent } from "react";
import { useState } from "react";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import * as yup from "yup";
import { passwordValidationRegex } from "src/utils/regexUtils";
import { Form, Formik } from "formik";
import { formikOnSubmitType } from "src/types/formTypes";
import { useUsersControllerResetPasswordMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useUsersControllerCheckResetPasswordCredQuery } from "src/app/services/api.generated";
import Loading from "src/components/atoms/Loading";
import { LoginLoadingBtn } from "src/components/atoms/LoginLoadingBtn";

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidationRegex.test(value);

type ResetPasswordFormPropsType = {
  resetCode: number;
  email: string;
};

export const ResetPasswordForm: FC<ResetPasswordFormPropsType> = ({
  resetCode,
  email,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const navigate = useNavigate(),
    { t } = useTranslation();

  const { isLoading, isError } = useUsersControllerCheckResetPasswordCredQuery({
    resetCode,
    email,
  });

  const [resetPassword, { isLoading: resetLoading }] =
    useUsersControllerResetPasswordMutation();

  const showPasswordHandler = () => setShowPassword((prevState) => !prevState);
  const showPasswordConfirmHandler = () =>
    setShowPasswordConfirm((prevState) => !prevState);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const formValidationSchema = yup.object({
    newPassword: yup
      .string()
      .test(t("passwordValidation"), t("changePassword.hint"), (value) =>
        passValidationHandler(value as string)
      )
      .required(t("thisSectionIsRequired")),
    newPasswordConfirm: yup
      .string()
      .oneOf([yup.ref("newPassword"), undefined], t("changePassword.repeat"))
      .required(t("thisSectionIsRequired")),
  });

  const formInitializer = {
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formSubmitHandler: formikOnSubmitType<typeof formInitializer> = (
    { newPassword },
    { setSubmitting }
  ) => {
    if (resetCode && email) {
      resetPassword({
        resetCode,
        email,
        changePasswordDto: { password: newPassword },
      })
        .unwrap()
        .then(() => {
          toast.success(t("changePassword.success"));
          navigate("/signIn");
        });
    }
    setSubmitting(false);
  };

  if (isError) {
    return <Navigate to="/" />;
  } else {
    if (isLoading) {
      return <Loading />;
    } else {
      return (
        <Formik
          initialValues={formInitializer}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ errors, touched, getFieldProps, values }) => (
            <Form autoComplete="off">
              <Stack rowGap={2}>
                <TextField
                  size="small"
                  label={t("newPassword") + " *"}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showPasswordHandler}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility
                              sx={{
                                fill: ({ palette }) =>
                                  palette.customColor.color3,
                                opacity: 0.5,
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                fill: ({ palette }) =>
                                  palette.customColor.color3,
                                opacity: 0.5,
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    errors["newPassword"] && touched["newPassword"]
                  )}
                  helperText={errors["newPassword"]}
                  {...getFieldProps("newPassword")}
                />
                <TextField
                  sx={{ mb: 1 }}
                  size="small"
                  label={t("confirmNewPassword") + " *"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showPasswordConfirmHandler}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordConfirm ? (
                            <Visibility
                              sx={{
                                fill: ({ palette }) =>
                                  palette.customColor.color3,
                                opacity: 0.5,
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                fill: ({ palette }) =>
                                  palette.customColor.color3,
                                opacity: 0.5,
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    errors["newPasswordConfirm"] &&
                      touched["newPasswordConfirm"]
                  )}
                  type={showPasswordConfirm ? "text" : "password"}
                  helperText={errors["newPasswordConfirm"]}
                  {...getFieldProps("newPasswordConfirm")}
                />
                <LoginLoadingBtn
                  disabled={Boolean(
                    !values.newPassword ||
                      !values.newPasswordConfirm ||
                      errors["newPasswordConfirm"] ||
                      errors["newPassword"]
                  )}
                  loading={resetLoading}
                  text={t("submit")}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      );
    }
  }
};
