import { createSlice, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/api";
import { LoginRo, UserRo } from "../services/api.generated";

type authSliceType = {
  user: UserRo | null;
  token: string | null;
  isAdmin: boolean;
};

const isAdmin = ({ roleNames, username }: UserRo) =>
  !!roleNames.find((item) => item === "ADMIN");

const initialState = () => {
  const localStorageUserInfo = localStorage.getItem("user");
  const localStorageUserToken = localStorage.getItem("token");
  let result: authSliceType = {
    user: null,
    token: null,
    isAdmin: false,
  };
  if (localStorageUserInfo && localStorageUserToken) {
    const userLocalInfo: UserRo = JSON.parse(localStorageUserInfo);
    const userLocalToken: LoginRo["token"] = localStorageUserToken;
    result = {
      user: userLocalInfo,
      token: userLocalToken,
      isAdmin: isAdmin(userLocalInfo),
    };
  }
  return result;
};

const isAuthenticated = (action: AnyAction) => {
  return (
    api.endpoints.authControllerLogin.matchFulfilled(action) ||
    api.endpoints.authControllerSignup.matchFulfilled(action)
  );
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return initialState();
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(
      isAuthenticated,
      (state, { payload: { token, user } }: { payload: LoginRo }) => {
        state.user = user;
        state.token = token;
        state.isAdmin =
          !!user.roleNames.find((item) => item === "ADMIN") ||
          user.username === "admin";
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
      }
    );
    addMatcher(
      api.endpoints.usersControllerUserProfile.matchFulfilled,
      (state, { payload }: { payload: UserRo }) => {
        state.user = payload;
      }
    );
    addMatcher(
      api.endpoints.usersControllerUpdateUserProfile.matchFulfilled,
      (state, { meta }) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...state.user,
            ...meta?.arg?.originalArgs?.userUpdateDto,
          })
        );
        state.user = {
          ...state.user!,
          ...meta?.arg?.originalArgs?.userUpdateDto,
        };
      }
    );
  },
});

export const { logout: logoutAction } = authSlice.actions;

export default authSlice.reducer;
