import { Slider, styled, useTheme } from "@mui/material";
import { useState, useMemo } from "react";

const minZoom = 2.5;
const maxZoom = 10;

export const ZoomExtractPages = () => {
  const [zoom, setZoom] = useState(4.5);

  const theme = useTheme();

  const CustomSLider = useMemo(
    () =>
      styled(Slider)({
        color: theme.palette.customColor.color5,
        height: 4,
        width: 113,
        "& .MuiSlider-rail": {
          backgroundColor: theme.palette.customColor.color6,
        },
        "& .MuiSlider-track": {
          border: "none",
        },
        "& .MuiSlider-thumb": {
          height: 8,
          width: 8,
          backgroundColor: theme.palette.customColor.color5,
          border: "2px solid currentColor",
          "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
          },
          "&:before": {
            display: "none",
          },
        },
      }),
    [theme]
  );

  const css = `
  ${theme.breakpoints.only("xs")}{
    .extractPage-wrapper{flex-basis:${(12 * zoom).toFixed()}%}
  }
  ${theme.breakpoints.only("sm")}{
    .extractPage-wrapper{flex-basis:${(12 * zoom).toFixed()}%}
  }
  ${theme.breakpoints.only("md")}{
    .extractPage-wrapper{flex-basis:${(6 * zoom).toFixed()}%}
  }
  ${theme.breakpoints.only("lg")}{
    .extractPage-wrapper{flex-basis:${(4 * zoom).toFixed()}%}
  }
  ${theme.breakpoints.only("xl")}{
    .extractPage-wrapper{flex-basis:${(3 * zoom).toFixed()}%}
  }`;

  return (
    <>
      <style>{css}</style>
      <CustomSLider
        size='small'
        min={minZoom}
        max={maxZoom}
        value={zoom}
        onChange={(_, value) => setZoom(value as number)}
      />
    </>
  );
};
