import type { FC } from "react";
import { useAppSelector } from "src/app/reduxCustomHooks";
import { Navigate, Outlet } from "react-router-dom";

const AdminPrivateRoute: FC = () => {
  const auth = useAppSelector((state) => state.auth);

  return auth?.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminPrivateRoute;
