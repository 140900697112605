import type { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const LogoutSvg: FC<SvgIconProps> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{ fill: "none" }}
      {...props}
    >
      <g clipPath="url(#clip0_63_356)">
        <path
          d="M11.2074 3.65871V0.961111C11.2074 0.53106 10.8557 0.179199 10.4255 0.179199H0.781912C0.35186 0.179199 0 0.53106 0 0.961111V1.46935V11.6603V12.0123C0 12.2988 0.169414 12.5725 0.41702 12.7028L6.35955 15.7785C6.62019 15.9088 6.93295 15.7265 6.93295 15.4266V12.4293H10.4255C10.8557 12.4293 11.2074 12.0776 11.2074 11.6474V7.37292H9.64358V10.4745C9.64358 10.696 9.47416 10.8655 9.25262 10.8655H6.94598V6.85164V4.31043V4.23224C6.94598 3.94554 6.77657 3.67187 6.52896 3.54155L3.02339 1.74315H9.25262C9.47416 1.74315 9.64358 1.91257 9.64358 2.13411V3.67187H11.2074V3.65871Z"
          fill={theme.palette.customColor.color11}
        />
        <path
          d="M13.2143 2.38155L15.7555 4.92277C16.0813 5.24856 16.0813 5.73074 15.7555 6.05654L13.2143 8.59775C12.8885 8.92355 12.3932 8.93671 12.0674 8.61092C11.7547 8.29828 11.7937 7.77688 12.0936 7.46411L13.2402 6.33034H8.79649C8.57508 6.33034 8.35341 6.23912 8.21006 6.0697C7.84516 5.67875 7.93639 4.98806 8.40553 4.72742C8.52282 4.66226 8.66617 4.62317 8.79649 4.62317H13.2404C13.2404 4.62317 12.1067 3.48939 12.0937 3.48939C11.7938 3.18966 11.7547 2.65535 12.0675 2.35562C12.3803 2.04286 12.8885 2.05576 13.2143 2.38155Z"
          fill={theme.palette.customColor.color11}
        />
      </g>
    </SvgIcon>
  );
};
