import { FC, Fragment, useCallback } from "react";
import { useState } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Typography,
  Tooltip,
  Table,
  TableContainer,
  TableBody,
  Paper,
  CircularProgress,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { DownloadSvg } from "src/components/atoms/svg/DownloadSvg";
import { ShareSvg } from "src/components/atoms/svg/ShareSvg";
import { BinSvg } from "src/components/atoms/svg/BinSvg";
import { PdfSvg } from "src/components/atoms/svg/PdfSvg";
import {
  TaskRo,
  useTasksControllerDeleteMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import moment from "moment";
// import { humanFileSize } from "src/utils/humanReadableFileSizeUtils";
import { HistoryTableBodyNestedRow } from "./HistoryTableBodyNestedRow";
import { tableOrderType } from "../HistoryTable";
import { getComparator } from "src/utils/sortTasks";
import TableSkeletonRow from "../../tableSkeleton/TableSkeletonRow";
import { useLazyTasksControllerZipSplitTasksQuery } from "src/app/services/api";

type HistoryTableBodyPropsType = {
  rows: TaskRo[];
  order: tableOrderType;
  orderBy: keyof TaskRo;
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
};

export const HistoryTableBody: FC<HistoryTableBodyPropsType> = ({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
  isLoading,
}) => {
  const [expandRow, setExpandRow] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<{
    anchor: HTMLButtonElement;
    index: number;
  } | null>(null);

  const { t } = useTranslation();

  const [deleteFile, { isLoading: deleteFileLoading }] =
    useTasksControllerDeleteMutation();

  const [getZipFile, { isLoading: getZipFileLoading }] =
    useLazyTasksControllerZipSplitTasksQuery();

  const expandHandler = (idx: number) => {
    setExpandRow((prevState) => {
      let result = null;
      if (prevState !== idx) {
        result = idx;
      }
      return result;
    });
  };

  const closePopover = () => setAnchorEl(null);

  const downloadFile = useCallback((url: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.click();
    toast.success(t("download.success"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popoverListItems = useCallback(
    ({ id }: TaskRo) => [
      {
        title: t("download"),
        icon: (
          <DownloadSvg
            sx={{
              fontSize: 19,
              "& > path": {
                fill: ({ palette }) => palette.customColor.color11,
              },
            }}
          />
        ),
        onClick: () => {
          getZipFile({ id })
            .unwrap()
            .then((res) => res.url && downloadFile(res.url))
            .finally(closePopover);
        },
        isLoading: getZipFileLoading,
      },
      {
        title: t("share"),
        icon: (
          <ShareSvg
            sx={{
              fontSize: 16,
              "& > path": {
                fill: ({ palette }) => palette.customColor.color11,
              },
            }}
          />
        ),
        onClick: () => {
          getZipFile({ id })
            .unwrap()
            .then((res) => {
              if (res.url) {
                navigator.clipboard.writeText(res.url);
                toast.success(t("copy.success"));
              }
            })
            .finally(closePopover);
        },
      },
      {
        title: t("delete"),
        icon: (
          <BinSvg
            sx={{
              fontSize: 16,
              "& > path": {
                fill: ({ palette }) => palette.customColor.color11,
              },
            }}
          />
        ),
        onClick: () => {
          deleteFile({ idDto: { id } })
            .unwrap()
            .then(() => toast.success(t("taskSuccessfullyDeleted")))
            .finally(closePopover);
        },
        isLoading: deleteFileLoading,
      },
    ],
    [
      deleteFile,
      deleteFileLoading,
      downloadFile,
      getZipFile,
      getZipFileLoading,
      t,
    ]
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <TableBody>
      {isLoading ? (
        <TableSkeletonRow cellCount={5} />
      ) : (
        (rows as any)
          .sort(getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row: TaskRo, index: number) => {
            const { name, splitPdfs, taskState, createdAt } = row;

            const realIndex = page * rowsPerPage + index;

            const enhancedCreatedAt = moment(createdAt)
              .fromNow()
              .replace("days ago", t("daysAgo"))
              .replace("day ago", t("dayAgo"));

            // const enhancedFileSize = humanFileSize(fileSize);

            const isExpanded = expandRow === index;

            return (
              <Fragment key={realIndex}>
                <TableRow hover tabIndex={-1}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => expandHandler(index)}
                    >
                      {isExpanded ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    scope="row"
                    sx={{
                      width: { xs: 305, md: 360, lg: 510 },
                    }}
                  >
                    <Tooltip title={name} arrow>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <PdfSvg />
                        <Typography
                          fontSize={14}
                          maxWidth={{ sm: 150, md: 250, lg: 300, xl: 450 }}
                          textOverflow="ellipsis"
                          noWrap
                        >
                          {name}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Typography fontSize={14} noWrap>
                      {enhancedCreatedAt}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="left">{enhancedFileSize}</TableCell> */}
                  <TableCell
                    align="left"
                    sx={{
                      color: taskState === "READY" ? green[500] : red[500],
                      fontWeight: taskState === "READY" ? 500 : undefined,
                    }}
                  >
                    {t(taskState)}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-describedby="more"
                      onClick={(e) =>
                        setAnchorEl({ anchor: e.currentTarget, index })
                      }
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Popover
                      elevation={1}
                      id="more"
                      open={anchorEl?.index === index}
                      anchorEl={
                        anchorEl?.index === index ? anchorEl?.anchor : undefined
                      }
                      onClose={closePopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{ p: 2 }}
                      PaperProps={{
                        sx: {
                          borderRadius: 0,
                          boxShadow: "0 4px 6px 0 rgba(0,0,0,0.15)",
                          py: 1.5,
                        },
                      }}
                    >
                      <List>
                        {popoverListItems(row).map(
                          ({ onClick, icon, title, isLoading }, index) => (
                            <ListItemButton
                              onClick={onClick}
                              key={index}
                              sx={{ px: 3.1, py: 1.3 }}
                            >
                              {isLoading ? (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{
                                    width: "100%",
                                    minWidth: 102,
                                    py: 0.5,
                                  }}
                                >
                                  <CircularProgress size={21} />
                                </Stack>
                              ) : (
                                <>
                                  <ListItemIcon sx={{ minWidth: 30 }}>
                                    {icon}
                                  </ListItemIcon>
                                  <ListItemText primary={title} />
                                </>
                              )}
                            </ListItemButton>
                          )
                        )}
                      </List>
                    </Popover>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    transformOrigin: "center top",
                    transform: `scaleY(${isExpanded ? 1 : 0})`,
                    transition: "250ms",
                    opacity: isExpanded ? 1 : 0,
                  }}
                >
                  {isExpanded && (
                    <TableCell sx={{ py: 5, px: 10 }} colSpan={6}>
                      <TableContainer
                        sx={{
                          overflow: "hidden",
                          boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
                        }}
                        component={Paper}
                      >
                        <Table>
                          <TableBody
                            sx={{
                              "&>tr:last-child": {
                                "&>td": {
                                  borderBottom: "none",
                                },
                              },
                            }}
                          >
                            {splitPdfs.map((pdf, index) => (
                              <HistoryTableBodyNestedRow
                                row={pdf}
                                key={index}
                                enhancedCreatedAt={enhancedCreatedAt}
                                taskState={taskState}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  )}
                </TableRow>
              </Fragment>
            );
          })
      )}
      {emptyRows > 0 && (
        <TableRow>
          <TableCell colSpan={7} />
        </TableRow>
      )}
    </TableBody>
  );
};
