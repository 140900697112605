import { FC, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoginTemplate from "src/components/templates/LoginTemplate";
import { Link, useNavigate } from "react-router-dom";
import { SignUpForm } from "src/components/organisms/forms/SignUpForm";
import { useAppSelector } from "src/app/reduxCustomHooks";

type SignUpPropsType = {};

export const SignUp: FC<SignUpPropsType> = () => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const token = useAppSelector((store) => store.auth.token);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  return (
    <LoginTemplate minHeight={640}>
      <Stack rowGap={1.5}>
        <Typography textAlign="center" fontSize={{ xs: 26 }} fontWeight="700">
          {t("signUp.title")}
        </Typography>
        <Typography textAlign="center">{t("welcome")}</Typography>
        <SignUpForm />
        <Stack
          direction="row"
          columnGap={0.5}
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{t("alreadyMember")}</Typography>
          <Link to="/signIn" style={{ textDecoration: "none" }}>
            <Typography color="primary">{t("signIn")}</Typography>
          </Link>
        </Stack>
      </Stack>
    </LoginTemplate>
  );
};
