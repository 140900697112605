import type { FC, MouseEventHandler, RefObject } from "react";
import { useState, useRef, useContext } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowDownSvg } from "./svg/ArrowDownSvg";
import { FolderSvg } from "./svg/FolderSvg";
import { DropBoxSvg } from "./svg/DropBoxSvg";
import { GoogleDriveSvg } from "./svg/GoogleDriveSvg";
import { LinkSvg } from "./svg/LinkSvg";
import DropboxChooser from "react-dropbox-chooser";
import { UploadFileContext } from "../context/UploadFileContext";
import { useLocation, useNavigate } from "react-router";
import { GetFileUrlDialog } from "../molecules/GetFileUrlDialog";
import { toast } from "react-toastify";
import { axiosGetFile } from "src/app/services/otherAPIs";

const DROPBOX_APP_KEY = process.env.REACT_APP_DROPBOX_APP_KEY;

export type dropboxOnSuccessType = {
  bytes: number;
  icon: string;
  id: string;
  isDir: boolean;
  link: string;
  linkType: string;
  name: string;
};

type UploadFileButtonPropsType = { inputRef?: RefObject<HTMLInputElement> };

export const UploadFileButton: FC<UploadFileButtonPropsType> = ({
  inputRef,
}) => {
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const { setFile, setAppStep, resetApp, appStep } =
    useContext(UploadFileContext);

  const { t } = useTranslation(),
    { pathname } = useLocation(),
    navigate = useNavigate();

  const handleToggle: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const dropboxChooserOnSuccess = (files: dropboxOnSuccessType[]) => {
    const { link, name } = files[0];
    axiosGetFile(link)
      .then(({ data }) => {
        const enhancedFile = new File([data], name);
        if (pathname === "/") {
          appStep !== 0 && resetApp();
          setFile(enhancedFile);
          setAppStep(1);
        } else {
          navigate("/", { state: { file: enhancedFile } });
        }
      })
      .catch((err) => {
        toast.error(t("fetch.catch.universalMessage"));
        err && console.log("Error", err);
      });
  };

  const getFileFromLocal = () => {
    if (pathname === "/") {
      if (appStep !== 0) {
        resetApp();
      } else {
        inputRef!.current?.click();
      }
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <ButtonGroup
        onClick={(e) => e.stopPropagation()}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ height: "40px" }}
      >
        <Button
          onClick={getFileFromLocal}
          sx={{
            borderRight: "none !important",
            px: 4,
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          <Typography fontWeight={700} fontSize={20} color="white">
            {t("uploadPdfFile")}
          </Typography>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            px: 0,
            minWidth: "32px !important",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          }}
        >
          <ArrowDownSvg />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10,
          width: 226,
          top: "6px !important",
          borderRadius: "4px !important",
          "&>.MuiPaper-root": {
            borderRadius: "4px !important",
          },
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ cursor: "default" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={getFileFromLocal} sx={{ pl: 6 }}>
                    <ListItemIcon>
                      <FolderSvg />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          color: ({ palette }) => palette.customColor.color3,
                        }}
                      >
                        {t("fromFiles")}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                  <DropboxChooser
                    appKey={DROPBOX_APP_KEY}
                    success={dropboxChooserOnSuccess}
                    cancel={() => console.log("cancel")}
                    multiselect={false}
                    extensions={[".pdf"]}
                    linkType="direct"
                  >
                    <MenuItem sx={{ pl: 6 }}>
                      <ListItemIcon>
                        <DropBoxSvg />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          fontSize={16}
                          fontWeight={500}
                          sx={{
                            color: ({ palette }) => palette.customColor.color3,
                          }}
                        >
                          {t("fromDropbox")}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </DropboxChooser>
                  <MenuItem onClick={() => {}} sx={{ pl: 6 }} disabled>
                    <ListItemIcon>
                      <GoogleDriveSvg />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          color: ({ palette }) => palette.customColor.color3,
                        }}
                      >
                        {t("fromDrive")}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setShowDialog(true)} sx={{ pl: 6 }}>
                    <ListItemIcon>
                      <LinkSvg />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          color: ({ palette }) => palette.customColor.color3,
                        }}
                      >
                        {t("fromLink")}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {showDialog && <GetFileUrlDialog onClose={() => setShowDialog(false)} />}
    </>
  );
};
