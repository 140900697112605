import type { FC } from "react";
import { useCallback, useContext } from "react";
import { Stack, Typography, Paper } from "@mui/material";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import uploadIcon from "src/assets/icons/upload.png";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import { UploadFileButton } from "src/components/atoms/UploadFileButton";

// Supported Size as !MB
const supportedSize = 80;

const dropzoneOptions = {
  maxSize: supportedSize * (1024 * 1024),
  accept: {
    "application/pdf": [".pdf"],
  },
  multiple: false,
};

type DropzonePropsType = {};

const Dropzone: FC<DropzonePropsType> = () => {
  const { t } = useTranslation();

  const { setFile, setAppStep } = useContext(UploadFileContext);

  const largeFileErrorMessage = useCallback(
    (fileName: string) =>
      t("dropzone.large", {
        fileName,
        maximumFileSize: supportedSize,
      }),
    [t]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.map(({ file, errors }) =>
        errors.forEach(({ code }) => {
          if (code === ErrorCode.FileTooLarge) {
            toast.error(largeFileErrorMessage(file.name));
          }
        })
      );
      acceptedFiles.forEach((file) => {
        if (file) {
          setFile(file);
          setAppStep(1);
        }
      });
    },
    [largeFileErrorMessage, setAppStep, setFile]
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    useFsAccessApi: false,
    ...dropzoneOptions,
  });

  return (
    <Paper
      elevation={0}
      sx={{
        border: ({ palette }) => "1px dashed" + palette.primary.main,
        bgcolor: ({ palette }) => palette.customColor.color7,
        cursor: "pointer",
        py: 2.5,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} id="dropzone" />
      <Stack
        justifyContent="center"
        alignItems="center"
        rowGap={2}
        columnGap={0}
        direction="column"
      >
        <img src={uploadIcon} alt="upload_dropzone" />
        <Typography sx={{ pb: 2 }} align="center">
          {t("dropzone.selectAFile")}
        </Typography>
        <UploadFileButton inputRef={inputRef} />
      </Stack>
    </Paper>
  );
};

export default Dropzone;
