import type { FC } from "react";
import { shallowEqual } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/app/reduxCustomHooks";

export const PrivateRoute: FC = () => {
  const token = useAppSelector((state) => state.auth?.token, shallowEqual);

  return token ? <Outlet /> : <Navigate to="/signIn" />;
};
