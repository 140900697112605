import type { FC } from "react";
import { Skeleton, TableCell, TableRow } from "@mui/material";

type TableSkeletonRowPropsType = {
  rowCount?: number;
  cellCount?: number;
};

const TableSkeletonRow: FC<TableSkeletonRowPropsType> = ({
  rowCount = 5,
  cellCount = 6,
}) => {
  return (
    <>
      {[...Array(rowCount)].map((_, index) => (
        <TableRow key={index}>
          {[...Array(cellCount)].map((_, index) => (
            <TableCell key={index}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeletonRow;
