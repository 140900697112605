import { FC, useMemo, useState } from "react";
import { useContext } from "react";
import { Box, Grid } from "@mui/material";
import { UploadFileContext } from "src/components/context/UploadFileContext";

import Loading from "src/components/atoms/Loading";
import { SmallPage } from "./page/SmallPage";
import { SinglePage } from "./page/SinglePage";
import { stringToColor } from "src/utils/avatar";
import { Document, pdfjs } from "react-pdf";

const address = process.env.PUBLIC_URL + "/scripts/pdf.worker.2.12.313.min.js";

pdfjs.GlobalWorkerOptions.workerSrc = address;

const threadsCount = 3;

export const Extract: FC = () => {
  const [pagesState, setPagesState] = useState<boolean[]>([]);

  const {
    file,
    showPage,
    setShowPage,
    pagesPdfData,
    setNumberOfPages,
    selectPage,
    changeCutState,
  } = useContext(UploadFileContext);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
    setPagesState([...Array(numPages)].map(() => false));
  };

  const maxRenderIndex: number = useMemo(() => {
    let notRendered = 0;
    let index = 0;
    for (; index < pagesState.length; index++) {
      if (!pagesState[index]) {
        notRendered++;
      }
      if (notRendered > threadsCount) {
        break;
      }
    }
    return index;
  }, [pagesState]);

  return (
    <Box
      sx={{
        overflow: "hidden",
        padding: 1,
      }}
    >
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Loading />}
        onLoadError={(err) => console.log(err)}
      >
        <Grid
          container
          sx={{ width: "100%" }}
          alignItems="stretch"
          justifyContent="center"
          rowGap={2}
        >
          {pagesPdfData.map(({ pdfIndex, cutState }, index: number) => (
            <SmallPage
              key={index}
              index={index}
              isLast={index === pagesPdfData.length - 1}
              isSelected={pdfIndex !== undefined}
              cutState={cutState}
              color={
                pdfIndex !== undefined
                  ? (stringToColor("" + pdfIndex) as string)
                  : ""
              }
              selectPage={() => selectPage(index)}
              changeCutState={() => changeCutState(index)}
              render={index <= maxRenderIndex}
              onRenderSuccess={() =>
                setPagesState(
                  pagesState.map((state, idx) => (idx === index ? true : state))
                )
              }
            />
          ))}
        </Grid>
        {showPage !== null && (
          <SinglePage
            pageNumber={showPage}
            setPageNumber={setShowPage}
            handleClose={() => setShowPage(null)}
          />
        )}
      </Document>
    </Box>
  );
};
