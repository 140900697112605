import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EmailSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{ fill: "none" }}
      {...props}
    >
      <path
        opacity="0.5"
        d="M0.334012 2.97362C2.52995 4.83334 6.38314 8.10516 7.51564 9.12531C7.66767 9.263 7.83073 9.333 8.00001 9.333C8.16895 9.333 8.33173 9.26366 8.48342 9.12663C9.61689 8.10547 13.4701 4.83334 15.666 2.97362C15.8028 2.85806 15.8236 2.65494 15.7129 2.51366C15.457 2.18719 15.0755 2 14.6667 2H1.33336C0.924512 2 0.542981 2.18719 0.287137 2.51369C0.17645 2.65494 0.197293 2.85806 0.334012 2.97362Z"
        fill="#403845"
      />
      <path
        opacity="0.5"
        d="M15.8067 3.98152C15.6885 3.92652 15.5495 3.9457 15.4512 4.0297C13.0159 6.09414 9.90788 8.74033 8.93 9.62148C8.38116 10.1169 7.61944 10.1169 7.06931 9.62083C6.027 8.6817 2.53675 5.71458 0.548813 4.02967C0.449844 3.94567 0.310531 3.92714 0.193344 3.98148C0.0755312 4.0362 0 4.15405 0 4.28392V12.6667C0 13.4021 0.597969 14.0001 1.33334 14.0001H14.6667C15.402 14.0001 16 13.4021 16 12.6667V4.28392C16 4.15405 15.9245 4.03589 15.8067 3.98152Z"
        fill="#403845"
      />
    </SvgIcon>
  );
};
