import type { FC } from "react";
import { Stack, Container, Typography, Grid } from "@mui/material";
import Dropzone from "./Dropzone";
import { useTranslation } from "react-i18next";
import { BORDER_RADIUS } from "src/configs/theme";

type GetFilePropsType = {};

export const GetFile: FC<GetFilePropsType> = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ bgcolor: "white", overflow: "hidden" }}>
      <Stack rowGap={4} sx={{ py: 4.5 }}>
        <Grid
          container
          gap={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={6.5}>
            <Typography
              align="center"
              fontSize={{ xs: 24, sm: 34, md: 44 }}
              fontWeight={700}
            >
              {t("getFile.box1.title")}
            </Typography>
            <Typography
              lineHeight="190%"
              align="center"
              fontSize={{ xs: 16, sm: 18, md: 20 }}
            >
              {t("getFile.box1.subtitle")}
            </Typography>
          </Grid>
          <Grid item md={5} display={{ xs: "none", md: "block" }}>
            <img
              src="/images/first_page.svg"
              alt="splitting"
              style={{ maxHeight: "35vh", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
        <Dropzone />
        <Grid
          container
          gap={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: 2,
            p: { xs: 2, md: 3 },
            borderRadius: BORDER_RADIUS[3],
            boxShadow: "inset 0px 0px 6px rgba(0,0,0,0.15)",
          }}
        >
          <Grid item md={5} display={{ xs: "none", md: "block" }}>
            <img src="/images/how_to.svg" alt="how to" />
          </Grid>
          <Grid item xs={12} md={6.7}>
            <Stack rowGap={1}>
              <Typography
                align="center"
                fontSize={{ xs: 20, sm: 24, md: 28 }}
                fontWeight={700}
              >
                {t("getFile.box2.title")}
              </Typography>
              <Typography align="left" fontSize={{ xs: 16, sm: 17, md: 18 }}>
                1- {t("getFile.box2.txt1")}
              </Typography>
              <Typography align="left" fontSize={{ xs: 16, sm: 17, md: 18 }}>
                2- {t("getFile.box2.txt2")}
              </Typography>
              <Typography align="left" fontSize={{ xs: 16, sm: 17, md: 18 }}>
                3- {t("getFile.box2.txt3")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};
