import type { FC } from "react";
import { Theme, SxProps, Stack, Toolbar } from "@mui/material";
import { Box } from "@mui/material";
import { Header } from "../organisms/layout/mainTemplate/Header";

export const sidebarWidth = 280;

type MainTemplatePropsType = {
  children?: any;
  sx?: SxProps<Theme>;
};

export const MainTemplate: FC<MainTemplatePropsType> = ({ children, sx }) => {
  return (
    <Stack
      sx={{
        bgcolor: "white",
        minHeight: "100vh",
        minWidth: "100vw",
        position: "relative",
      }}
    >
      <Header />
      <Box
        sx={{
          ...sx,
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Stack>
  );
};
