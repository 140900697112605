import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownloadSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
      sx={{ ...props.sx, fill: "none" }}
    >
      <path
        d="M31.5 28.5H4.5C4.10218 28.5 3.72064 28.658 3.43934 28.9393C3.15804 29.2206 3 29.6022 3 30C3 30.3978 3.15804 30.7794 3.43934 31.0607C3.72064 31.342 4.10218 31.5 4.5 31.5H31.5C31.8978 31.5 32.2794 31.342 32.5607 31.0607C32.842 30.7794 33 30.3978 33 30C33 29.6022 32.842 29.2206 32.5607 28.9393C32.2794 28.658 31.8978 28.5 31.5 28.5Z"
        fill="#FFB72B"
      />
      <path
        d="M18 3C17.6022 3 17.2207 3.15804 16.9394 3.43934C16.6581 3.72064 16.5 4.10218 16.5 4.5V20.385L11.565 15.435C11.2826 15.1525 10.8995 14.9939 10.5 14.9939C10.1006 14.9939 9.71749 15.1525 9.43503 15.435C9.15258 15.7175 8.9939 16.1005 8.9939 16.5C8.9939 16.8995 9.15258 17.2825 9.43503 17.565L16.935 25.065C17.0745 25.2056 17.2404 25.3172 17.4232 25.3933C17.606 25.4695 17.802 25.5087 18 25.5087C18.1981 25.5087 18.3941 25.4695 18.5769 25.3933C18.7597 25.3172 18.9256 25.2056 19.065 25.065L26.565 17.565C26.7049 17.4251 26.8158 17.2591 26.8915 17.0764C26.9672 16.8936 27.0062 16.6978 27.0062 16.5C27.0062 16.3022 26.9672 16.1064 26.8915 15.9236C26.8158 15.7409 26.7049 15.5749 26.565 15.435C26.4252 15.2951 26.2591 15.1842 26.0764 15.1085C25.8937 15.0328 25.6978 14.9939 25.5 14.9939C25.3022 14.9939 25.1064 15.0328 24.9237 15.1085C24.7409 15.1842 24.5749 15.2951 24.435 15.435L19.5 20.385V4.5C19.5 4.10218 19.342 3.72064 19.0607 3.43934C18.7794 3.15804 18.3979 3 18 3Z"
        fill="#FFB72B"
      />
    </SvgIcon>
  );
};
