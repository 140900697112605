import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import EmptyPage from "src/components/organisms/emptyPage/EmptyPage";

export const Notfound: FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyPage
      titleElement={
        <>
          <Typography variant="h1" align="center" paragraph fontWeight="bolder">
            404
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ mb: 6 }}>
            {t("404Message")}
          </Typography>
        </>
      }
    />
  );
};
