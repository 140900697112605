import type { FC } from "react";
import { Stack } from "@mui/material";
import { ExtractSidebar } from "../organisms/layout/extractTemplate/ExtractSidebar";
import { ExtractHeader } from "../organisms/layout/extractTemplate/ExtractHeader";
import { sidebarWidth } from "./MainTemplate";

type ExtractTemplatePropsType = {
  children?: any;
};

export const ExtractTemplate: FC<ExtractTemplatePropsType> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="stretch"
      sx={{
        bgcolor: "#F5F5F5",
        minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
      }}
    >
      <Stack sx={{ width: { md: `calc(100% - ${sidebarWidth}px)` } }}>
        <ExtractHeader />
        <div style={{ width: "100%", height: "56px" }} />
        {children}
      </Stack>
      <ExtractSidebar />
    </Stack>
  );
};
