import type { FC } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { useUsersControllerMailResetPasswordMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { formikOnSubmitType } from "src/types/formTypes";
import { useTranslation } from "react-i18next";
import { EmailSvg } from "src/components/atoms/svg/EmailSvg";
import { LoginLoadingBtn } from "src/components/atoms/LoginLoadingBtn";

export const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation();

  const [sendMail, { isLoading }] =
    useUsersControllerMailResetPasswordMutation();

  const formInitialValues = {
    email: "",
  };

  const formValidation = yup.object().shape({
    email: yup.string().email(),
  });

  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { email },
    { setSubmitting }
  ) => {
    if (!email) return;
    sendMail({
      mailResetPasswordDto: {
        email,
        callbackUrl: window.location.origin + "/resetPassword/",
      },
    })
      .unwrap()
      .then(() => {
        toast.success(t("sendMail.toast"));
      });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidation}
      onSubmit={submitHandler}
    >
      {({ errors, touched, getFieldProps, values }) => (
        <Form autoComplete="on">
          <Stack rowGap={{ xs: 2.5, lg: 4.2 }}>
            <TextField
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "send",
                autoComplete: "on",
                name: "email",
              }}
              label={t("email") + " *"}
              autoFocus
              error={Boolean(errors.email && touched.email)}
              helperText={errors.email}
              {...getFieldProps("email")}
            />
            <LoginLoadingBtn
              disabled={Boolean(!values.email || errors.email)}
              loading={isLoading}
              text={t("sendEmail")}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
