import type { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { MainTemplate } from "src/components/templates/MainTemplate";

export const Pricing: FC = () => {
  return (
    <MainTemplate>
      <Stack
        sx={{
          width: "100",
          height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 65px)" },
        }}
        alignItems="center"
        justifyContent="center"
        rowGap={6}
      >
        <Typography variant="h2">Pricing page</Typography>
        <Typography variant="h6">Coming soon...</Typography>
      </Stack>
    </MainTemplate>
  );
};
