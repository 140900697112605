import type { FC, MouseEvent } from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuthControllerLoginMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { formikOnSubmitType } from "src/types/formTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { EmailSvg } from "src/components/atoms/svg/EmailSvg";
import { LockSvg } from "src/components/atoms/svg/LockSvg";
import { Link } from "react-router-dom";
import { LoginLoadingBtn } from "src/components/atoms/LoginLoadingBtn";

type SignInFormPropsType = {};

export const SignInForm: FC<SignInFormPropsType> = () => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const [loginUser, { isLoading }] = useAuthControllerLoginMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const frequentValidation = yup
    .string()
    .min(3, t("tooShort"))
    .max(50, t("tooLong"))
    .required(t("required"));

  const formInitialValues = {
    username: "",
    password: "",
  };
  const formValidation = yup.object().shape({
    username: frequentValidation,
    password: frequentValidation,
  });
  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { username, password },
    { setSubmitting }
  ) => {
    loginUser({
      loginDto: { username, password },
    })
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success(t("login.200"));
          navigate("/");
        }
      })
      .catch(
        ({ status }: { status: number }) =>
          (status === 401 || status === 404) && toast.error(t("login.401"))
      );
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidation}
      onSubmit={submitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="on">
          <Stack rowGap={2}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "username",
              }}
              label={t("username") + " *"}
              autoFocus
              error={Boolean(errors.username && touched.username)}
              helperText={errors.username}
              {...getFieldProps("username")}
            />
            <TextField
              size="small"
              fullWidth
              label={t("password") + " *"}
              type={showPassword ? "text" : "password"}
              inputProps={{
                enterKeyHint: "done",
                autoComplete: "on",
                name: "password",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility
                          sx={{
                            fill: ({ palette }) => palette.customColor.color3,
                            opacity: 0.5,
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{
                            fill: ({ palette }) => palette.customColor.color3,
                            opacity: 0.5,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password && touched.password)}
              helperText={errors.password}
              {...getFieldProps("password")}
            />
            <Link to="/forgotPassword" style={{ textDecoration: "none" }}>
              <Typography
                fontSize="14px"
                color="primary"
                fontWeight={600}
                align="right"
              >
                {t("forgotYourPassword")}
              </Typography>
            </Link>
            <LoginLoadingBtn loading={isLoading} text={t("signIn")} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
