import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LinkSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      sx={{
        fill: "none",
      }}
      {...props}
    >
      <path
        d="M17.19 5.4285L16.4609 4.69937C15.1999 3.43842 13.1477 3.43843 11.8867 4.69939L8.76113 7.82496C7.4991 9.08699 7.50016 11.1382 8.76112 12.3992L9.49025 13.1283C10.1014 13.7395 10.9131 14.0772 11.7763 14.0762C12.6405 14.0762 13.4532 13.7395 14.0644 13.1283L17.19 10.0027C18.451 8.74175 18.451 6.68946 17.19 5.4285ZM10.0478 9.11165L13.1734 5.98608C13.449 5.7105 13.8114 5.57328 14.1738 5.57327C14.5362 5.57327 14.8986 5.7105 15.1742 5.98606L15.9033 6.71519C16.4555 7.2674 16.4545 8.16487 15.9033 8.71602L12.7778 11.8416C12.5097 12.1097 12.1548 12.2566 11.7773 12.2566C11.3999 12.2566 11.0439 12.1086 10.7769 11.8416L10.0467 11.1135C9.49669 10.5613 9.4956 9.66387 10.0478 9.11165Z"
        fill="#EDEDED"
      />
      <path
        d="M16.166 6.23379L15.6567 5.72448C14.7764 4.84417 13.2763 4.90959 12.3156 5.87031L9.932 8.2539C8.97021 9.2157 8.90479 10.7158 9.78509 11.5961L10.2955 12.1043C10.7201 12.5311 11.3045 12.7509 11.9349 12.723C12.5654 12.6951 13.1702 12.4228 13.6355 11.9574L16.0191 9.57383C16.9798 8.6131 17.0463 7.1141 16.166 6.23379ZM12.7777 11.8416C12.5097 12.1097 12.1548 12.2566 11.7773 12.2566C11.3999 12.2566 11.0439 12.1086 10.7769 11.8416L10.0467 11.1136C9.49667 10.5614 9.49558 9.66391 10.0478 9.11169L13.1734 5.98611C13.4489 5.71054 13.8114 5.57331 14.1738 5.5733C14.5362 5.5733 14.8986 5.71054 15.1742 5.9861L15.9033 6.71523C16.4555 7.26743 16.4545 8.1649 15.9033 8.71605L12.7777 11.8416Z"
        fill="#DBD8DD"
      />
      <path
        d="M11.2393 7.59867L10.5101 6.86954C9.24919 5.60858 7.19692 5.6086 5.93596 6.86955L2.81038 9.99513C1.54835 11.2572 1.54941 13.3084 2.81037 14.5693L3.5395 15.2984C4.15067 15.9096 4.96237 16.2474 5.82553 16.2463C6.68976 16.2463 7.5025 15.9096 8.1137 15.2984L11.2393 12.1729C12.5002 10.9119 12.5002 8.85963 11.2393 7.59867ZM4.09708 11.2818L7.22265 8.15624C7.49822 7.88067 7.86063 7.74345 8.22307 7.74343C8.58548 7.74343 8.9479 7.88067 9.22346 8.15623L9.95259 8.88536C10.5048 9.43757 10.5037 10.335 9.95259 10.8862L6.82701 14.0118C6.55895 14.2798 6.20403 14.4267 5.8266 14.4267C5.44917 14.4267 5.09318 14.2787 4.82619 14.0118L4.09599 13.2837C3.54594 12.7315 3.54486 11.834 4.09708 11.2818Z"
        fill="#DE594E"
      />
      <path
        d="M10.2153 8.40396L9.70601 7.89464C8.8257 7.01434 7.32563 7.07975 6.36491 8.04048L3.98132 10.4241C3.01952 11.3859 2.9541 12.8859 3.83441 13.7662L4.3448 14.2745C4.7694 14.7012 5.35377 14.921 5.98426 14.8932C6.61473 14.8653 7.21947 14.5929 7.68483 14.1276L10.0684 11.744C11.0291 10.7833 11.0956 9.28426 10.2153 8.40396ZM6.82705 14.0118C6.55899 14.2798 6.20407 14.4268 5.82664 14.4268C5.44921 14.4268 5.09322 14.2788 4.82623 14.0118L4.09603 13.2837C3.54598 12.7315 3.5449 11.8341 4.09712 11.2819L7.22269 8.15628C7.49826 7.8807 7.86067 7.74348 8.22311 7.74347C8.58552 7.74347 8.94794 7.8807 9.2235 8.15626L9.95263 8.88539C10.5048 9.4376 10.5038 10.3351 9.95263 10.8862L6.82705 14.0118Z"
        fill="#CD5248"
      />
      <path
        d="M12.9356 11.6839L12.7777 11.8417C12.5097 12.1098 12.1548 12.2567 11.7773 12.2567C11.3999 12.2567 11.0439 12.1087 10.7769 11.8417L10.0468 11.1136C9.49668 10.5615 9.49561 9.66397 10.0478 9.11177L10.0996 9.05995C10.0557 8.999 10.0075 8.94021 9.95264 8.88539L9.22351 8.15625C9.08288 8.01562 8.91947 7.91149 8.74514 7.84277C7.50011 9.10613 7.50583 11.144 8.76111 12.3993L9.49024 13.1284C10.1014 13.7396 10.9131 14.0774 11.7763 14.0763C12.6405 14.0763 13.4532 13.7396 14.0644 13.1284L14.2223 12.9706L12.9356 11.6839Z"
        fill="#EDEDED"
      />
      <path
        d="M12.9356 11.6839L12.7777 11.8417C12.5097 12.1097 12.1548 12.2566 11.7773 12.2567C11.3999 12.2566 11.0439 12.1087 10.7769 11.8417L10.0467 11.1136C9.49667 10.5614 9.4956 9.66393 10.0478 9.11173L10.0996 9.0599C10.0557 8.99896 10.0075 8.94016 9.95263 8.88534L9.6542 8.58691C8.97095 9.5362 9.00045 10.8115 9.78509 11.5961L10.2955 12.1044C10.7201 12.5311 11.3045 12.7509 11.935 12.7231C12.468 12.6995 12.9816 12.4994 13.4092 12.1575L12.9356 11.6839Z"
        fill="#DBD8DD"
      />
    </SvgIcon>
  );
};
