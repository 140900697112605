import type { FC, MouseEventHandler } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

type ConfirmDialogPropsType = {
  onConfirm: () => any;
  loading?: boolean;
  disabled?: boolean;
  dialogProp: DialogProps;
};

export const ConfirmDialog: FC<ConfirmDialogPropsType> = ({
  onConfirm,
  loading,
  disabled,
  dialogProp,
}) => {
  const { t } = useTranslation();

  const cancelHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (dialogProp.onClose) {
      dialogProp.onClose(e, "escapeKeyDown");
    }
  };

  return (
    <Dialog
      {...dialogProp}
      maxWidth="sm"
      PaperProps={{ sx: { ...dialogProp.PaperProps?.sx, width: "100%" } }}
    >
      <DialogTitle align="center" color="error">
        {t("attention")}!
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center" fontSize={20}>
          {t("confirm.message")}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <LoadingButton
          size="large"
          fullWidth
          loading={loading}
          disabled={disabled}
          onClick={onConfirm}
          variant="outlined"
          color="error"
          sx={{ fontSize: 20 }}
        >
          {t("yes")}
        </LoadingButton>
        <Button
          size="large"
          fullWidth
          variant="contained"
          onClick={cancelHandler}
          color="primary"
          sx={{ fontSize: 20 }}
        >
          {t("no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
