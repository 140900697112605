import type { FC, MouseEvent, ChangeEvent } from "react";
import { useMemo, useState } from "react";
import { Table, TableContainer, TablePagination, Box } from "@mui/material";
import { liveSearch } from "src/utils/liveSearchUtils";
import { UserRo } from "src/app/services/api.generated";
import { AdminPanelTableToolbar } from "./AdminPanelTableToolbar";
import { AdminPanelTableHeader } from "./AdminPanelTableHeader";
import { AdminPanelTableBody } from "./AdminPanelTableBody";

export type tableOrderType = "asc" | "desc";

type AdminPanelTablePropsType = {
  data?: UserRo[];
  isLoading: boolean;
};

export const AdminPanelTable: FC<AdminPanelTablePropsType> = ({
  data = [],
  isLoading,
}) => {
  const [order, setOrder] = useState<tableOrderType>("asc");
  const [orderBy, setOrderBy] = useState<keyof UserRo>("username");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string>("");

  const rows = useMemo(() => {
    return liveSearch({
      originalArray: data,
      inputValue: search,
      searchItem: "username",
    });
  }, [data, search]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof UserRo
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) =>
    setPage(newPage);

  const handleChangeRowsPerPage = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  return (
    <>
      <AdminPanelTableToolbar search={search} setSearch={setSearch} />
      <Box
        sx={{
          boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
          borderRadius: 0,
        }}
      >
        <TableContainer>
          <Table stickyHeader>
            <AdminPanelTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <AdminPanelTableBody
              isLoading={isLoading}
              rows={rows}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};
