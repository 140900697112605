import { createTheme, PaletteColor, PaletteColorOptions } from "@mui/material";
import { CSSProperties } from "react";
import { breakpointGenerator } from "src/utils/breakpointGenerator";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    text1: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    text1: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    text1: true;
    h6: true;
    h5: true;
    h4: true;
    h3: true;
    h2: true;
    h1: true;
    subtitle1: true;
    subtitle2: true;
    body1: true;
    body2: true;
    button: true;
    caption: true;
    overline: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    customColor: PaletteColor & {
      color1: string;
      color2: string;
      color3: string;
      color4: string;
      color5: string;
      color6: string;
      color7: string;
      color8: string;
      color9: string;
      color10: string;
      color11: string;
      color12: string;
    };
  }
  interface PaletteOptions {
    customColor: PaletteColorOptions & {
      color1: string;
      color2: string;
      color3: string;
      color4: string;
      color5: string;
      color6: string;
      color7: string;
      color8: string;
      color9: string;
      color10: string;
      color11: string;
      color12: string;
    };
  }
}

export const BORDER_RADIUS = {
  0: "2px",
  1: "8px",
  2: "10px",
  3: "24px",
  4: "32px",
};

//! #403845 with 0.6 opacity ==> color9
//! #403845 with 0.5 opacity ==> color11

const theme = createTheme({
  palette: {
    primary: { light: "#F9C664", main: "#FFB72B", dark: "#FF9944" },
    customColor: {
      color1: "#F3F3F3",
      color2: "rgba(0,0,0,0.1)",
      color3: "#403845",
      color4: "#9FA2B4",
      color5: "#8D8D8D",
      color6: "#CECECE",
      color7: "rgba(255, 183, 43, 0.15)",
      color8: "#FF7F50",
      color9: "rgba(64,56,69,0.6)",
      color10: "#E1E1E1",
      color11: "rgba(64,56,69,0.5)",
      color12: "#3FB56E",
    },
  },
  typography: {
    // htmlFontSize: 16 (MUI default),
    button: {
      ...breakpointGenerator([12, 16]),
      textTransform: "capitalize",
    },
    text1: { fontSize: "clamp(1rem, 2.5vw, 2rem)" },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "block",
        },
      },
      defaultProps: {
        color: "#403845",
      },
    },
    MuiListItemText: {
      defaultProps: {
        color: "#403845",
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        contained: {
          color: "white",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        contained: {
          color: "white",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          // border: "none",
        },
      },
    },
  },
});

export default theme;
