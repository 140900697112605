import { FC } from "react";
import { Container, Stack } from "@mui/material";
import { SettingsSidebar } from "../organisms/layout/settingsTemplate/SettingsSidebar";
import { BORDER_RADIUS } from "src/configs/theme";

export const settingsSidebarWidth = 285;

type SettingsTemplatePropsType = {
  children?: any;
};

export const SettingsTemplate: FC<SettingsTemplatePropsType> = ({
  children,
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        maxWidth: "100%",
        overflow: "hidden",
      }}
      alignItems="start"
    >
      <SettingsSidebar />
      <Container
        sx={{
          my: { lg: 3, xl: 4 },
          mx: { lg: 2, xl: undefined },
          py: { xs: 2, sm: 3, md: 4, lg: 5, xl: 6 },
          px: { xs: 2, sm: 3, md: 6, lg: 8, xl: 10 },
          boxShadow: { xs: "none", lg: "0 0 6px 0 rgba(0,0,0,0.15)" },
          borderRadius: { xs: 0, lg: BORDER_RADIUS[3] },
        }}
      >
        {children}
      </Container>
    </Stack>
  );
};
