import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckedSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z'
        fill='#3FB56E'
      />
      <path
        d='M8.93359 17.4132L15.1039 23.5835C20.2144 22.2208 23.9998 17.5646 23.9998 12C23.9998 11.8864 23.9998 11.7728 23.9998 11.6593L19.1544 7.19238L8.93359 17.4132Z'
        fill='#35975C'
      />
      <path
        d='M12.303 14.6877C12.833 15.2177 12.833 16.1262 12.303 16.6562L11.2052 17.754C10.6753 18.2839 9.76675 18.2839 9.23679 17.754L4.42921 12.9085C3.89925 12.3786 3.89925 11.4701 4.42921 10.9401L5.52701 9.8423C6.05698 9.31233 6.96549 9.31233 7.49546 9.8423L12.303 14.6877Z'
        fill='white'
      />
      <path
        d='M16.5048 6.32179C17.0348 5.79182 17.9433 5.79182 18.4733 6.32179L19.5711 7.41958C20.1011 7.94955 20.1011 8.85807 19.5711 9.38804L11.243 17.6783C10.7131 18.2082 9.80453 18.2082 9.27456 17.6783L8.17677 16.5805C7.6468 16.0505 7.6468 15.142 8.17677 14.612L16.5048 6.32179Z'
        fill='white'
      />
    </SvgIcon>
  );
};
