import type { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const ProfileSvg: FC<SvgIconProps> = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{ fill: "none" }}
      {...props}
    >
      <g clipPath="url(#clip0_63_344)">
        <path
          d="M7.88597 7.70724C8.94481 7.70724 9.86167 7.32749 10.6108 6.57822C11.36 5.82908 11.7397 4.91246 11.7397 3.8535C11.7397 2.79491 11.36 1.87817 10.6107 1.12878C9.86143 0.379759 8.94468 0 7.88597 0C6.82702 0 5.91039 0.379759 5.16125 1.1289C4.41211 1.87804 4.03223 2.79479 4.03223 3.8535C4.03223 4.91246 4.41211 5.8292 5.16137 6.57834C5.91064 7.32736 6.82738 7.70724 7.88597 7.70724Z"
          fill={theme.palette.customColor.color11}
        />
        <path
          d="M14.629 12.3031C14.6074 11.9913 14.5637 11.6512 14.4993 11.2921C14.4344 10.9303 14.3508 10.5882 14.2507 10.2756C14.1473 9.95251 14.0067 9.63342 13.8328 9.32763C13.6524 9.01025 13.4405 8.73388 13.2027 8.50647C12.9541 8.26855 12.6496 8.07727 12.2976 7.93774C11.9467 7.79895 11.5579 7.72864 11.1421 7.72864C10.9787 7.72864 10.8208 7.79565 10.5157 7.99426C10.328 8.1167 10.1084 8.2583 9.86325 8.41491C9.65365 8.54846 9.36972 8.67358 9.01901 8.78686C8.67685 8.89758 8.32944 8.95373 7.98655 8.95373C7.64365 8.95373 7.29636 8.89758 6.95384 8.78686C6.6035 8.6737 6.31956 8.54858 6.11021 8.41504C5.86741 8.25989 5.64769 8.11828 5.45714 7.99414C5.15245 7.79553 4.99437 7.72852 4.83104 7.72852C4.41503 7.72852 4.02636 7.79895 3.67565 7.93787C3.32384 8.07715 3.01928 8.26843 2.77038 8.50659C2.53271 8.73413 2.32067 9.01037 2.1405 9.32763C1.96679 9.63342 1.82617 9.95238 1.72265 10.2757C1.62268 10.5884 1.53906 10.9303 1.47412 11.2921C1.40979 11.6507 1.36609 11.991 1.34448 12.3034C1.32324 12.6096 1.3125 12.9273 1.3125 13.2483C1.3125 14.0835 1.578 14.7596 2.10156 15.2583C2.61865 15.7503 3.30285 16 4.13488 16H11.8389C12.671 16 13.3549 15.7505 13.8721 15.2583C14.3958 14.76 14.6613 14.0837 14.6613 13.2481C14.6612 12.9258 14.6503 12.6078 14.629 12.3031Z"
          fill={theme.palette.customColor.color11}
        />
      </g>
    </SvgIcon>
  );
};
