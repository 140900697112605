import type { FC } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Home } from "src/pages/Home";
import { Notfound } from "src/components/organisms/Notfound";
import { PrivateRoute } from "./PrivateRoute";
import { SignIn } from "src/pages/SignIn";
import { SignUp } from "src/pages/SignUp";
import { ForgotPassword } from "src/pages/ForgotPassword";
import { History } from "src/pages/History";
import { Profile } from "src/pages/Profile";
import { AdminPanel } from "src/pages/AdminPanel";
import { Pricing } from "src/pages/Pricing";
import { Help } from "src/pages/Help";
import { ResetPassword } from "src/pages/ResetPassword";
import AdminPrivateRoute from "./AdminPrivateRoute";
import { AddUser } from "src/pages/AddUser";

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/*" element={<ResetPassword />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/reset" element={<Navigate to="/" replace />} />
          <Route path="/history" element={<History />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/help" element={<Help />} />
          <Route path="/adminPanel" element={<AdminPrivateRoute />}>
            <Route path="/adminPanel" element={<AdminPanel />} />
          </Route>
          <Route path="/addUser" element={<AdminPrivateRoute />}>
            <Route path="/addUser" element={<AddUser />} />
          </Route>
        </Route>
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
