import type { FC } from "react";
import { MainTemplate } from "src/components/templates/MainTemplate";
import { SettingsTemplate } from "src/components/templates/SettingsTemplate";
import { HistoryTable } from "src/components/organisms/history/HistoryTable";
import { useTasksControllerFindAllQuery } from "src/app/services/api.generated";
// import { useAppSelector } from "src/app/reduxCustomHooks";

export const History: FC = () => {
  // const selectedUser = useAppSelector(
  //   (store) => store.appSettings.selectedUser
  // );

  const { data, isLoading } = useTasksControllerFindAllQuery();

  return (
    <MainTemplate>
      <SettingsTemplate>
        <HistoryTable data={data || []} isLoading={isLoading} />
      </SettingsTemplate>
    </MainTemplate>
  );
};
