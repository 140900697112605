import { FC, useState, MouseEvent } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuthControllerSignupMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { formikOnSubmitType } from "src/types/formTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { EmailSvg } from "src/components/atoms/svg/EmailSvg";
import { LockSvg } from "src/components/atoms/svg/LockSvg";
import { Link } from "react-router-dom";
import { ProfileSvg } from "src/components/atoms/svg/ProfileSvg";
import { IdCardSvg } from "src/components/atoms/svg/IdCardSvg";
import { passwordValidationRegex } from "src/utils/regexUtils";
import { LoginLoadingBtn } from "src/components/atoms/LoginLoadingBtn";

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidationRegex.test(value);

type SignUpFormPropsType = {};

export const SignUpForm: FC<SignUpFormPropsType> = () => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const [signUpHandler, { isLoading }] = useAuthControllerSignupMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const frequentValidation = yup
    .string()
    .min(3, t("tooShort"))
    .max(50, t("tooLong"))
    .required(t("required"));

  const formInitialValues = {
    username: "",
    name: "",
    email: "",
    password: "",
  };
  const formValidation = yup.object().shape({
    username: frequentValidation,
    name: frequentValidation,
    email: yup.string().email(),
    password: yup
      .string()
      .test(t("passwordValidation"), t("changePassword.hint"), (value) =>
        passValidationHandler(value as string)
      ),
  });
  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { username, name, email, password },
    { setSubmitting }
  ) => {
    signUpHandler({
      userCreateDto: { username, name, email, password, isActive: true },
    })
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success(t("login.200"));
          navigate("/");
        }
      })
      .catch(({ status }: { status: number }) => {
        status === 401 && toast.error(t("login.401"));
      });
    setSubmitting(false);
  };

  const termsAndConditions = (
    <Stack
      direction="row"
      columnGap={0.5}
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      sx={{ maxWidth: "100%" }}
    >
      <Typography fontSize={{ xs: "10px", sm: "12px" }}>
        {t("byCreatingAnAccountYouAgreeToScanPBL")}
      </Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Typography
          color="primary"
          fontSize={{ xs: "10px", sm: "12px" }}
          sx={{
            borderBottom: ({ palette }) => "1px solid " + palette.primary.main,
          }}
        >
          {t("termsOfService")}
        </Typography>
      </Link>
      <Typography fontSize={{ xs: "10px", sm: "12px" }}>
        {" "}
        {t("and")}{" "}
      </Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Typography
          color="primary"
          fontSize={{ xs: "10px", sm: "12px" }}
          sx={{
            borderBottom: ({ palette }) => "1px solid " + palette.primary.main,
          }}
        >
          {t("privacyPolicy")}
        </Typography>
      </Link>
    </Stack>
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidation}
      onSubmit={submitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="on">
          <Stack rowGap={2}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IdCardSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "username",
              }}
              label={t("username") + " *"}
              autoFocus
              error={Boolean(errors.username && touched.username)}
              helperText={errors.username}
              {...getFieldProps("username")}
            />
            <TextField
              size="small"
              fullWidth
              label={t("name") + " *"}
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "name",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ProfileSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.name && touched.name)}
              helperText={errors.name}
              {...getFieldProps("name")}
            />
            <TextField
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "email",
              }}
              label={t("email") + " *"}
              autoFocus
              error={Boolean(errors.email && touched.email)}
              helperText={errors.email}
              {...getFieldProps("email")}
            />
            <TextField
              size="small"
              fullWidth
              label={t("password") + " *"}
              type={showPassword ? "text" : "password"}
              inputProps={{
                enterKeyHint: "done",
                autoComplete: "on",
                name: "password",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility
                          sx={{
                            fill: ({ palette }) => palette.customColor.color3,
                            opacity: 0.5,
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{
                            fill: ({ palette }) => palette.customColor.color3,
                            opacity: 0.5,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password && touched.password)}
              helperText={errors.password}
              {...getFieldProps("password")}
            />
            {termsAndConditions}
            <LoginLoadingBtn loading={isLoading} text={t("signUp")} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
