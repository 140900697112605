import { FC, useEffect } from "react";
import LoginTemplate from "src/components/templates/LoginTemplate";
import { SignInForm } from "src/components/organisms/forms/SignInForm";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/app/reduxCustomHooks";

export const SignIn: FC = () => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const token = useAppSelector((store) => store.auth.token);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  return (
    <LoginTemplate minHeight={400}>
      <Stack rowGap={2}>
        <Typography textAlign="center" fontSize={{ xs: 26 }} fontWeight="700">
          {t("signIn.title")}
        </Typography>
        <Typography textAlign="center">{t("welcome")}</Typography>
        <SignInForm />
        <Stack
          direction="row"
          columnGap={0.5}
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{t("dontHaveAnAccountYet?")}</Typography>
          <Link to="/signUp" style={{ textDecoration: "none" }}>
            <Typography color="primary">{t("signUp")}</Typography>
          </Link>
        </Stack>
      </Stack>
    </LoginTemplate>
  );
};
