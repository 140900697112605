import type { FC } from "react";
import { Fragment, useContext, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import { useTranslation } from "react-i18next";
import { RightSvg } from "src/components/atoms/svg/RightSvg";
import theme from "src/configs/theme";
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";
import { rangesToString } from "src/utils/range";
import { hexToRgb, stringToColor } from "src/utils/avatar";
import { sidebarWidth } from "src/components/templates/MainTemplate";

const CustomCheckbox = styled(Checkbox)({
  "& > svg": {
    "& > path": {
      fill: theme.palette.customColor.color4,
    },
  },
  "&.Mui-checked": {
    "& > svg": {
      "& > path": {
        fill: theme.palette.primary.main,
      },
    },
  },
});

export const ExtractSidebar: FC = () => {
  const [mergeAllRanges, setMergeAllRanges] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const { pdfsRanges, addPdfRange, deletePdfRange, submitSplit } =
    useContext(UploadFileContext);

  const submitRangeHandler = () => {
    addPdfRange({
      from: +start - 1,
      to: +end - 1,
    });

    setStart("");
    setEnd("");
  };

  const showSidebarBtn = (
    <IconButton
      sx={{
        height: "70px",
        position: "fixed",
        top: "50%",
        right: showSidebar ? sidebarWidth - 20 : -20,
        transform: "translateY(-50%)",
        display: { md: "none" },
        bgcolor: "white",
        zIndex: 10000,
        "&:hover": { bgcolor: "white" },
      }}
      onClick={() => setShowSidebar((prevState) => !prevState)}
    >
      {showSidebar ? <ArrowForwardIos /> : <ArrowBackIos />}
    </IconButton>
  );

  return (
    <>
      {!showSidebar && showSidebarBtn}
      <Drawer
        onClose={() => setShowSidebar(false)}
        open={showSidebar}
        anchor="right"
        variant={isMd ? "permanent" : "temporary"}
        sx={{
          mt: { md: "64px" },
        }}
        PaperProps={{
          sx: {
            width: sidebarWidth,
            mt: { md: "64px" },
            height: { md: "calc(100vh - 64px)" },
          },
        }}
      >
        {showSidebar && showSidebarBtn}
        <Stack
          rowGap={1.5}
          sx={{ p: 2, overflowX: "hidden", overflowY: "auto" }}
        >
          {pdfsRanges.map((pdfRanges, pdfIndex: number) => (
            <Fragment key={pdfIndex}>
              <Stack rowGap={0.5} justifyContent="center" key={pdfIndex}>
                <Typography fontWeight={900} color="customColor.color9">
                  {`${t("ranges")} ${pdfIndex + 1}`}
                </Typography>
                {pdfRanges.map((range, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={1}
                    key={index}
                  >
                    <TextField
                      disabled
                      size="small"
                      className="small-input"
                      placeholder={t("from")}
                      type="number"
                      value={range.from + 1}
                    />
                    <TextField
                      disabled
                      size="small"
                      className="small-input"
                      placeholder={t("to")}
                      type="number"
                      value={range.to + 1}
                    />
                  </Stack>
                ))}
              </Stack>
              <Divider />
            </Fragment>
          ))}
          <Stack direction="row" alignItems="center" columnGap={1}>
            <TextField
              size="small"
              className="small-input"
              placeholder={t("from")}
              type="number"
              value={start}
              onChange={({ target: { value } }) => {
                if (Number(value) > 0 || value === "") {
                  setStart(value);
                }
              }}
            />
            <TextField
              size="small"
              className="small-input"
              placeholder={t("to")}
              type="number"
              value={end}
              onChange={({ target: { value } }) => {
                if (Number(value) > 0 || value === "") {
                  setEnd(value);
                }
              }}
            />
          </Stack>
          <Button variant="outlined" onClick={submitRangeHandler}>
            {t("addNewRange")}
          </Button>
        </Stack>

        <Stack
          rowGap={1}
          sx={{ mt: "auto", boxShadow: "0 -4px 4px 0 rgba(0,0,0,0.05)", p: 2 }}
        >
          <Typography fontWeight="bold">{t("rangesToExtract")}:</Typography>
          <Stack
            direction="row"
            columnGap={1}
            sx={{
              border: ({ palette }) =>
                "1px solid" + palette.customColor.color10,
              borderRadius: "5px",
              p: 0.5,
              width: "100%",
              minHeight: 30,
              maxHeight: 120,
              overflowY: "auto",
            }}
            flexWrap="wrap"
            gap={0.5}
          >
            {pdfsRanges.map((pdfRanges, pdfIndex) => {
              const rgb = hexToRgb(stringToColor("" + pdfIndex, 0.8));
              return (
                <Chip
                  size="small"
                  deleteIcon={
                    <Close
                      sx={{
                        "& > path": { fill: "white" },
                        bgcolor: "error.main",
                        borderRadius: "50px",
                      }}
                    />
                  }
                  onDelete={() => deletePdfRange(pdfIndex)}
                  key={pdfIndex}
                  label={rangesToString(pdfRanges)}
                  color="primary"
                  sx={{
                    bgcolor:
                      rgb && `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.3)`,
                  }}
                />
              );
            })}
          </Stack>
          <FormControlLabel
            control={
              <CustomCheckbox
                value={mergeAllRanges}
                onClick={() => setMergeAllRanges((prevState) => !prevState)}
              />
            }
            label={t("mergeAllRangesInOnePDFFile")}
            sx={{
              "& > span": { fontSize: "12px" },
            }}
          />
          <Button
            size="small"
            fullWidth
            disabled={pdfsRanges.length === 0}
            variant="contained"
            onClick={submitSplit}
            endIcon={<RightSvg />}
          >
            <Typography fontWeight="bold" fontSize="20px" color="white">
              {t("extractPdf")}
            </Typography>
          </Button>
        </Stack>
      </Drawer>
    </>
  );
};
