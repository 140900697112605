import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/app/services/baseQuery";
export const api = createApi({
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    usersControllerCreate: build.mutation<
      UsersControllerCreateApiResponse,
      UsersControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerUpdate: build.mutation<
      UsersControllerUpdateApiResponse,
      UsersControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "PUT",
        body: queryArg.adminUserUpdateDto,
      }),
    }),
    usersControllerFind: build.query<
      UsersControllerFindApiResponse,
      UsersControllerFindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: {
          username: queryArg.username,
          name: queryArg.name,
          email: queryArg.email,
          documentCount: queryArg.documentCount,
        },
      }),
    }),
    usersControllerDelete: build.mutation<
      UsersControllerDeleteApiResponse,
      UsersControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "DELETE",
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerUserProfile: build.query<
      UsersControllerUserProfileApiResponse,
      UsersControllerUserProfileApiArg
    >({
      query: () => ({ url: `/api/v1/users/profile` }),
    }),
    usersControllerUpdateUserProfile: build.mutation<
      UsersControllerUpdateUserProfileApiResponse,
      UsersControllerUpdateUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        method: "POST",
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerSuspend: build.mutation<
      UsersControllerSuspendApiResponse,
      UsersControllerSuspendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend`,
        method: "PATCH",
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerActivate: build.mutation<
      UsersControllerActivateApiResponse,
      UsersControllerActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/activate`,
        method: "POST",
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerAssignRole: build.mutation<
      UsersControllerAssignRoleApiResponse,
      UsersControllerAssignRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role`,
        method: "POST",
        body: queryArg.userRoleDto,
      }),
    }),
    usersControllerCheckPermission: build.mutation<
      UsersControllerCheckPermissionApiResponse,
      UsersControllerCheckPermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/checkPermission`,
        method: "POST",
        body: queryArg.checkPermissionDto,
      }),
    }),
    usersControllerMailResetPassword: build.mutation<
      UsersControllerMailResetPasswordApiResponse,
      UsersControllerMailResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/mailResetPassword`,
        method: "POST",
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    usersControllerCheckResetPasswordCred: build.query<
      UsersControllerCheckResetPasswordCredApiResponse,
      UsersControllerCheckResetPasswordCredApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/checkResetPasswordCred`,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerResetPassword: build.mutation<
      UsersControllerResetPasswordApiResponse,
      UsersControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/resetPassword`,
        method: "POST",
        body: queryArg.changePasswordDto,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerChangePassword: build.mutation<
      UsersControllerChangePasswordApiResponse,
      UsersControllerChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/changePassword`,
        method: "POST",
        body: queryArg.changePasswordDto,
      }),
    }),
    usersControllerPaginationUserTable: build.query<
      UsersControllerPaginationUserTableApiResponse,
      UsersControllerPaginationUserTableApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/userPagination`,
        params: { nested: queryArg.nested },
      }),
    }),
    usersControllerSendVerifyEmail: build.mutation<
      UsersControllerSendVerifyEmailApiResponse,
      UsersControllerSendVerifyEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/sendVerificationEmail`,
        method: "POST",
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "POST",
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "PUT",
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "DELETE",
        body: queryArg.roleIdDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    mediaControllerUploadDocument: build.mutation<
      MediaControllerUploadDocumentApiResponse,
      MediaControllerUploadDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    tasksControllerFindAll: build.query<
      TasksControllerFindAllApiResponse,
      TasksControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/all` }),
    }),
    tasksControllerGetTask: build.query<
      TasksControllerGetTaskApiResponse,
      TasksControllerGetTaskApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tasks/task/${queryArg.id}` }),
    }),
    tasksControllerTaskPagination: build.query<
      TasksControllerTaskPaginationApiResponse,
      TasksControllerTaskPaginationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/pagination`,
        params: queryArg,
      }),
    }),
    tasksControllerGetUsage: build.query<
      TasksControllerGetUsageApiResponse,
      TasksControllerGetUsageApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/usage` }),
    }),
    tasksControllerRename: build.mutation<
      TasksControllerRenameApiResponse,
      TasksControllerRenameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/rename`,
        method: "PATCH",
        body: queryArg.splitTaskRenameDto,
      }),
    }),
    tasksControllerDelete: build.mutation<
      TasksControllerDeleteApiResponse,
      TasksControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    tasksControllerDeleteMany: build.mutation<
      TasksControllerDeleteManyApiResponse,
      TasksControllerDeleteManyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/many`,
        method: "DELETE",
        body: queryArg.idsDto,
      }),
    }),
    tasksControllerZipSplitTasks: build.query<
      TasksControllerZipSplitTasksApiResponse,
      TasksControllerZipSplitTasksApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tasks/zip/${queryArg.id}` }),
    }),
    tasksControllerGetTaskSplittedPdfsUrl: build.query<
      TasksControllerGetTaskSplittedPdfsUrlApiResponse,
      TasksControllerGetTaskSplittedPdfsUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/splitted-urls/${queryArg.id}`,
      }),
    }),
    tasksControllerGetSplittedPdfUrl: build.query<
      TasksControllerGetSplittedPdfUrlApiResponse,
      TasksControllerGetSplittedPdfUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/splitted-pdf-url/${queryArg.id}`,
      }),
    }),
  }),
});
export type UsersControllerCreateApiResponse = /** status 201  */ UserRo;
export type UsersControllerCreateApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerUpdateApiResponse = unknown;
export type UsersControllerUpdateApiArg = {
  adminUserUpdateDto: AdminUserUpdateDto;
};
export type UsersControllerFindApiResponse = /** status 200  */ UsersRo;
export type UsersControllerFindApiArg = {
  username?: string;
  name?: string;
  email?: string;
  documentCount?: number;
};
export type UsersControllerDeleteApiResponse = unknown;
export type UsersControllerDeleteApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerUserProfileApiResponse = /** status 200  */ UserRo;
export type UsersControllerUserProfileApiArg = void;
export type UsersControllerUpdateUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerUpdateUserProfileApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerSuspendApiResponse = unknown;
export type UsersControllerSuspendApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerActivateApiResponse = unknown;
export type UsersControllerActivateApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerAssignRoleApiResponse = unknown;
export type UsersControllerAssignRoleApiArg = {
  userRoleDto: UserRoleDto;
};
export type UsersControllerCheckPermissionApiResponse = unknown;
export type UsersControllerCheckPermissionApiArg = {
  checkPermissionDto: CheckPermissionDto;
};
export type UsersControllerMailResetPasswordApiResponse = unknown;
export type UsersControllerMailResetPasswordApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type UsersControllerCheckResetPasswordCredApiResponse = unknown;
export type UsersControllerCheckResetPasswordCredApiArg = {
  resetCode: number;
  email: string;
};
export type UsersControllerResetPasswordApiResponse = unknown;
export type UsersControllerResetPasswordApiArg = {
  resetCode: number;
  email: string;
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerChangePasswordApiResponse = unknown;
export type UsersControllerChangePasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerPaginationUserTableApiResponse =
  /** status 200  */ UsersRo;
export type UsersControllerPaginationUserTableApiArg = {
  nested?: UserPaginationParamsDto;
};
export type UsersControllerSendVerifyEmailApiResponse = unknown;
export type UsersControllerSendVerifyEmailApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerSignupApiResponse = /** status 201  */ LoginRo;
export type AuthControllerSignupApiArg = {
  userCreateDto: UserCreateDto;
};
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RolesRo;
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  roleIdDto: RoleIdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type MediaControllerUploadDocumentApiResponse =
  /** status 201  */ TaskRo;
export type MediaControllerUploadDocumentApiArg = {
  body: {
    file: Blob;
    pdfsRanges: PageRangeDto[][];
  };
};
export type TasksControllerFindAllApiResponse = /** status 200  */ TaskRo[];
export type TasksControllerFindAllApiArg = void;
export type TasksControllerGetTaskApiResponse = /** status 200  */ TaskRo;
export type TasksControllerGetTaskApiArg = {
  id: string;
};
export type TasksControllerTaskPaginationApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerTaskPaginationApiArg = TaskPaginationParamsDto;
export type TasksControllerGetUsageApiResponse = /** status 200  */ UsageRo;
export type TasksControllerGetUsageApiArg = void;
export type TasksControllerRenameApiResponse = unknown;
export type TasksControllerRenameApiArg = {
  splitTaskRenameDto: SplitTaskRenameDto;
};
export type TasksControllerDeleteApiResponse = unknown;
export type TasksControllerDeleteApiArg = {
  idDto: IdDto;
};
export type TasksControllerDeleteManyApiResponse = unknown;
export type TasksControllerDeleteManyApiArg = {
  idsDto: IdsDto;
};
export type TasksControllerZipSplitTasksApiResponse = /** status 200  */ UrlRo;
export type TasksControllerZipSplitTasksApiArg = {
  id: string;
};
export type TasksControllerGetTaskSplittedPdfsUrlApiResponse =
  /** status 200  */ TaskSplittedPdfsUrl[];
export type TasksControllerGetTaskSplittedPdfsUrlApiArg = {
  id: string;
};
export type TasksControllerGetSplittedPdfUrlApiResponse =
  /** status 200  */ UrlRo;
export type TasksControllerGetSplittedPdfUrlApiArg = {
  id: string;
};
export type UserRo = {
  username: string;
  name: string;
  email: string;
  roleNames: string[];
  isActive: boolean;
  emailVerified: boolean;
  phoneVerified: boolean;
  documentCount: number;
  lastLogin: string;
};
export type UserCreateDto = {
  username: string;
  password: string;
  name: string;
  email: string;
  isActive: boolean;
};
export type AdminUserUpdateDto = {
  username: string;
  name?: string;
  email?: string;
  documentCount?: number;
};
export type UsersRo = {
  users: UserRo[];
};
export type UsernameDto = {
  username: string;
};
export type UserUpdateDto = {
  username: string;
  name?: string;
  email?: string;
};
export type UserRoleDto = {
  username: string;
  roleIds: string[];
};
export type CheckPermissionDto = {
  username: string;
  permissions: "ADMIN"[];
};
export type MailResetPasswordDto = {
  email: string;
  callbackUrl: string;
};
export type ChangePasswordDto = {
  password: string;
};
export type UserPaginationParamsDto = {
  offset?: number;
  limit?: number;
  sortingCriteria?: string;
  sortingOrder?: boolean;
  username?: string;
  isActive?: boolean;
  name?: string;
  email?: string;
  lastLogin?: string;
};
export type LoginRo = {
  token: string;
  user: UserRo;
};
export type LoginDto = {
  username: string;
  password: string;
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: "ADMIN";
};
export type RoleDto = {
  name: string;
  permissions: "ADMIN"[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: "ADMIN"[];
};
export type RolesRo = {
  roles: RoleRo[];
};
export type RoleIdDto = {
  id?: string;
};
export type SplitPdfRo = {
  id: string;
  name: string;
  fileSize: number;
};
export type TaskRo = {
  id: string;
  creator: string;
  name: string;
  filePath: string;
  splitPdfs: SplitPdfRo[];
  taskState: "PENDING" | "READY" | "ERROR";
  createdAt: string;
  zipFileName: string;
  isDeleted: boolean;
};
export type PageRangeDto = {
  from: number;
  to: number;
};
export type TasksRo = {
  tasks: TaskRo[];
};
export type TaskPaginationParamsDto = {
  offset?: number;
  limit?: number;
  sortingCriteria?: string;
  sortingOrder?: boolean;
  search?: string;
  creator?: string;
  name?: string;
  createdAt?: string;
  folderName?: string;
  isDeleted?: boolean;
};
export type UsageRo = {
  workspaceCount: number;
  taskCount: number;
  pageCount: number;
};
export type SplitTaskRenameDto = {
  taskId: string;
  newName: string;
};
export type IdDto = {
  id: string;
};
export type IdsDto = {
  ids: string[];
};
export type UrlRo = {
  url: string;
};
export type TaskSplittedPdfsUrl = {
  id: string;
  url: string;
};
export const {
  useUsersControllerCreateMutation,
  useUsersControllerUpdateMutation,
  useUsersControllerFindQuery,
  useUsersControllerDeleteMutation,
  useUsersControllerUserProfileQuery,
  useUsersControllerUpdateUserProfileMutation,
  useUsersControllerSuspendMutation,
  useUsersControllerActivateMutation,
  useUsersControllerAssignRoleMutation,
  useUsersControllerCheckPermissionMutation,
  useUsersControllerMailResetPasswordMutation,
  useUsersControllerCheckResetPasswordCredQuery,
  useUsersControllerResetPasswordMutation,
  useUsersControllerChangePasswordMutation,
  useUsersControllerPaginationUserTableQuery,
  useUsersControllerSendVerifyEmailMutation,
  useAuthControllerLoginMutation,
  useAuthControllerSignupMutation,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useMediaControllerUploadDocumentMutation,
  useTasksControllerFindAllQuery,
  useTasksControllerGetTaskQuery,
  useTasksControllerTaskPaginationQuery,
  useTasksControllerGetUsageQuery,
  useTasksControllerRenameMutation,
  useTasksControllerDeleteMutation,
  useTasksControllerDeleteManyMutation,
  useTasksControllerZipSplitTasksQuery,
  useTasksControllerGetTaskSplittedPdfsUrlQuery,
  useTasksControllerGetSplittedPdfUrlQuery,
} = api;
