import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SelectAllSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M16.25 23.8334H4.33333C3.14166 23.8334 2.16666 22.8584 2.16666 21.6668V9.75008C2.16666 9.10008 2.6 8.66675 3.25 8.66675C3.9 8.66675 4.33333 9.10008 4.33333 9.75008V21.6668H16.25C16.9 21.6668 17.3333 22.1001 17.3333 22.7501C17.3333 23.4001 16.9 23.8334 16.25 23.8334Z' />
      <path d='M21.6667 19.5001H8.66667C7.475 19.5001 6.5 18.5251 6.5 17.3334V4.33341C6.5 3.14175 7.475 2.16675 8.66667 2.16675H21.6667C22.8583 2.16675 23.8333 3.14175 23.8333 4.33341V17.3334C23.8333 18.5251 22.8583 19.5001 21.6667 19.5001ZM8.66667 4.33341V17.3334H21.6667V4.33341H8.66667Z' />
      <path d='M19.175 9.42492C19.6083 8.99158 19.6083 8.34159 19.175 7.90825C18.7417 7.47492 18.0917 7.47492 17.6583 7.90825L19.175 9.42492ZM14.0833 12.9999L13.325 13.7583C13.7583 14.1916 14.4083 14.1916 14.8417 13.7583L14.0833 12.9999ZM12.675 10.0749C12.2417 9.64159 11.5917 9.64159 11.1583 10.0749C10.725 10.5083 10.725 11.1583 11.1583 11.5916L12.675 10.0749ZM17.6583 7.90825L13.325 12.2416L14.8417 13.7583L19.175 9.42492L17.6583 7.90825ZM14.8417 12.2416L12.675 10.0749L11.1583 11.5916L13.325 13.7583L14.8417 12.2416Z' />
    </SvgIcon>
  );
};
