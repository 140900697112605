import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BinSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      sx={{ fill: "none" }}
      {...props}
    >
      <path
        d="M2.91577 7.03125L4.16119 22.0657C4.25044 23.1499 5.17397 24 6.26232 24H17.7376C18.8259 24 19.7494 23.1499 19.8387 22.0657L21.0841 7.03125H2.91577ZM8.48363 21.1875C8.11557 21.1875 7.80591 20.9012 7.78257 20.5283L7.07944 9.18455C7.0554 8.79661 7.34996 8.46291 7.73724 8.43886C8.13891 8.41069 8.45822 8.70872 8.48293 9.09666L9.18605 20.4404C9.21094 20.8421 8.89308 21.1875 8.48363 21.1875ZM12.703 20.4844C12.703 20.873 12.3886 21.1875 11.9999 21.1875C11.6113 21.1875 11.2968 20.873 11.2968 20.4844V9.14062C11.2968 8.75198 11.6113 8.4375 11.9999 8.4375C12.3886 8.4375 12.703 8.75198 12.703 9.14062V20.4844ZM16.9204 9.18459L16.2173 20.5283C16.1941 20.8974 15.8865 21.205 15.4716 21.1861C15.0843 21.1621 14.7897 20.8284 14.8138 20.4405L15.5169 9.0967C15.5409 8.70877 15.8808 8.42653 16.2626 8.43891C16.6499 8.46295 16.9444 8.79666 16.9204 9.18459Z"
        fill="white"
      />
      <path
        d="M21.1406 2.8125H16.9219V2.10938C16.9219 0.946219 15.9757 0 14.8125 0H9.1875C8.02434 0 7.07812 0.946219 7.07812 2.10938V2.8125H2.85938C2.0827 2.8125 1.45312 3.44208 1.45312 4.21875C1.45312 4.99533 2.0827 5.625 2.85938 5.625C9.32653 5.625 14.6737 5.625 21.1406 5.625C21.9173 5.625 22.5469 4.99533 22.5469 4.21875C22.5469 3.44208 21.9173 2.8125 21.1406 2.8125ZM15.5156 2.8125H8.48438V2.10938C8.48438 1.72144 8.79956 1.40625 9.1875 1.40625H14.8125C15.2004 1.40625 15.5156 1.72144 15.5156 2.10938V2.8125Z"
        fill="white"
      />
    </SvgIcon>
  );
};
