import type { FC, Dispatch, SetStateAction, ReactNode } from "react";
import { createContext, useState, useMemo, useEffect } from "react";
import { useCustomMediaControllerUploadDocumentMutation } from "src/app/services/api";
import { PageRangeDto, TaskRo } from "src/app/services/api.generated";
import { sortType } from "src/types/generalTypes";
import { getPagesPdfData, pagesToRange } from "src/utils/range";
import Loading from "../atoms/Loading";

const emptyFunction = () => {};

export type PagesType = { isSelected: boolean; cutState: boolean };
export type PagesPdfDataType = { pdfIndex?: number; cutState: boolean };

type UploadFileContextType = {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  task: TaskRo | null;
  numberOfPages: number;
  setNumberOfPages: Dispatch<SetStateAction<number>>;
  pdfsRanges: PageRangeDto[][];
  pagesPdfData: PagesPdfDataType[];
  addPdfRange: (range: PageRangeDto) => void;
  deletePdfRange: (pdfIndex: number, rangeIndex?: number) => void;
  showPage: number | null;
  setShowPage: Dispatch<SetStateAction<number | null>>;
  appStep: 0 | 1 | 2;
  setAppStep: Dispatch<SetStateAction<0 | 1 | 2>>;
  resetApp: () => void;
  selectPage: (pageIndex: number) => void;
  changeCutState: (pageIndex: number) => void;
  splitAllPages: () => void;
  selectAllPages: () => void;
  resetPages: () => void;
  submitSplit: () => void;
  sort: sortType;
  setSort: Dispatch<SetStateAction<sortType>>;
};

export const UploadFileContext = createContext<UploadFileContextType>({
  file: null,
  setFile: emptyFunction,
  task: null,
  numberOfPages: 0,
  setNumberOfPages: emptyFunction,
  pdfsRanges: [],
  pagesPdfData: [],
  addPdfRange: emptyFunction,
  deletePdfRange: emptyFunction,
  showPage: null,
  setShowPage: emptyFunction,
  appStep: 0,
  setAppStep: emptyFunction,
  resetApp: emptyFunction,
  selectPage: emptyFunction,
  changeCutState: emptyFunction,
  splitAllPages: emptyFunction,
  selectAllPages: emptyFunction,
  resetPages: emptyFunction,
  submitSplit: emptyFunction,
  sort: "asc",
  setSort: emptyFunction,
});

export const UploadFileContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [file, setFile] = useState<File | null>(null);
  const [sort, setSort] = useState<sortType>("asc");
  const [task, setTask] = useState<TaskRo | null>(null);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pages, setPages] = useState<PagesType[]>([]);
  const [showPage, setShowPage] = useState<number | null>(null);
  const [appStep, setAppStep] = useState<0 | 1 | 2>(0);

  useEffect(() => {
    setPages(
      [...Array(numberOfPages)].map(() => ({
        isSelected: true,
        cutState: false,
      }))
    );
  }, [numberOfPages]);

  const resetApp = () => {
    setFile(null);
    setNumberOfPages(0);
    setPages([]);
    setShowPage(null);
    setAppStep(0);
    setSort("asc");
  };

  const pagesPdfData = useMemo(() => getPagesPdfData(pages), [pages]);

  const pdfsRanges = useMemo(() => pagesToRange(pages), [pages]);

  const [upload, { isLoading }] =
    useCustomMediaControllerUploadDocumentMutation();

  const submitSplit = async () => {
    if (!file) return;
    const res = await upload({
      body: {
        file,
        pdfsRanges,
      },
    }).unwrap();
    setTask(res);
    setAppStep(2);
  };

  const addPdfRange = ({ from, to }: PageRangeDto) => {
    if (from < 0 || to >= pages.length) {
      return;
    }

    setPages((prev) => {
      for (let i = from; i < to; i++) {
        prev[i].isSelected = true;
        prev[i].cutState = false;
      }
      prev[to].isSelected = true;
      return [...prev];
    });
  };
  const deletePdfRange = (pdfIndex: number, rangeIndex?: number) => {
    if (!rangeIndex) {
      setPages(
        pagesPdfData.map((pageData, index) =>
          pageData.pdfIndex === pdfIndex
            ? { ...pages[index], isSelected: false }
            : pages[index]
        )
      );
    }
  };
  const selectPage = (pageIndex: number) => {
    setPages((prev) => {
      prev[pageIndex].isSelected = !prev[pageIndex].isSelected;
      return [...prev];
    });
  };

  const changeCutState = (pageIndex: number) => {
    setPages((prev) => {
      prev[pageIndex].cutState = !prev[pageIndex].cutState;
      return [...prev];
    });
  };

  const splitAllPages = () => {
    const haveNotCutted = pages.some((page) => page.cutState === false);
    setPages(
      pages.map((page) => ({ ...page, cutState: haveNotCutted ? true : false }))
    );
  };
  const selectAllPages = () => {
    const haveNotSelected = pages.some((page) => page.isSelected === false);
    setPages(
      pages.map((page) => ({
        ...page,
        isSelected: haveNotSelected ? true : false,
      }))
    );
  };
  const resetPages = () =>
    setPages(
      pages.map(() => ({
        isSelected: false,
        cutState: false,
      }))
    );

  return (
    <UploadFileContext.Provider
      value={{
        file,
        setFile,
        task,
        numberOfPages,
        setNumberOfPages,
        pdfsRanges,
        pagesPdfData,
        addPdfRange,
        deletePdfRange,
        showPage,
        setShowPage,
        appStep,
        setAppStep,
        resetApp,
        selectPage,
        changeCutState,
        splitAllPages,
        selectAllPages,
        resetPages,
        submitSplit,
        sort,
        setSort,
      }}
    >
      {isLoading ? <Loading /> : children}
    </UploadFileContext.Provider>
  );
};
