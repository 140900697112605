import type { FC } from "react";
import { MainTemplate } from "src/components/templates/MainTemplate";
import { SettingsTemplate } from "src/components/templates/SettingsTemplate";
import { UserInfo } from "src/components/organisms/profile/UserInfo";
import { ChangePassword } from "src/components/organisms/changePassword/ChangePassword";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Profile: FC = () => {
  const { t } = useTranslation();

  return (
    <MainTemplate>
      <SettingsTemplate>
        <Grid container justifyContent={{ xs: "center", md: "space-between" }}>
          <Grid item xs={12} sx={{ pl: { xs: 3, sm: 4 } }}>
            <Typography fontSize={30} fontWeight={700}>
              {t("profile")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={5.8}>
            <UserInfo />
          </Grid>
          <Grid item xs={12} sm={9} md={5.8}>
            <ChangePassword />
          </Grid>
        </Grid>
      </SettingsTemplate>
    </MainTemplate>
  );
};
