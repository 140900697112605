import { Box, Stack, Typography } from "@mui/material";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { AddUserForm } from "src/components/organisms/forms/AddUserForm";
import { MainTemplate } from "src/components/templates/MainTemplate";
import { SettingsTemplate } from "src/components/templates/SettingsTemplate";
import { BORDER_RADIUS } from "src/configs/theme";

export const AddUser: FC = () => {
  const { t } = useTranslation();
  return (
    <MainTemplate>
      <SettingsTemplate>
        <Stack>
          <Typography
            fontSize={30}
            fontWeight={700}
            sx={{
              py: 3,
            }}
          >
            {t("addUser")}
          </Typography>
          <Box
            sx={{
              boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
              borderRadius: BORDER_RADIUS[1],
              p: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography color="customColor.color3" fontSize={20} sx={{ mb: 4 }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </Typography>
            <Box
              sx={{
                // p: { xs: 1, sm: 1.5, md: 2, lg: 3 },
                maxWidth: 560,
                ml: { xs: 0, md: 3, lg: 6 },
              }}
            >
              <AddUserForm />
            </Box>
          </Box>
        </Stack>
      </SettingsTemplate>
    </MainTemplate>
  );
};
