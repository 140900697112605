import { FC, useEffect } from "react";
import LoginTemplate from "src/components/templates/LoginTemplate";
import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ForgotPasswordForm } from "src/components/organisms/forms/ForgotPasswordForm";
import { useAppSelector } from "src/app/reduxCustomHooks";

export const ForgotPassword: FC = () => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const token = useAppSelector((store) => store.auth.token);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  return (
    <LoginTemplate minHeight={500}>
      <Stack rowGap={{ xs: 2.5, lg: 4.2 }}>
        <Typography
          textAlign="center"
          fontSize={{ xs: 26, sm: 30 }}
          fontWeight="700"
        >
          {t("forgotPassword.title")}
        </Typography>
        <Typography textAlign="center" fontSize={{ xs: 16, sm: 17 }}>
          {t("forgotPassword.subtitle")}
        </Typography>
        <ForgotPasswordForm />
        <Stack
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderColor: ({ palette }) => palette.customColor.color3,
                opacity: 0.2,
                width: "70%",
                margin: "0 auto",
              }}
            />
          }
          rowGap={1}
        >
          <Stack
            direction="row"
            columnGap={0.5}
            alignItems="center"
            justifyContent="center"
          >
            <Typography>{t("alreadyMember")}</Typography>
            <Link to="/signIn" style={{ textDecoration: "none" }}>
              <Typography color="primary">{t("signIn")}</Typography>
            </Link>
          </Stack>
          <Stack
            direction="row"
            columnGap={0.5}
            alignItems="center"
            justifyContent="center"
          >
            <Typography>{t("dontHaveAnAccountYet?")}</Typography>
            <Link to="/signUp" style={{ textDecoration: "none" }}>
              <Typography color="primary">{t("signUp")}</Typography>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </LoginTemplate>
  );
};
