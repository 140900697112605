import { IconButton, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { SplitPdfRo } from "src/app/services/api.generated";
import { useLazyTasksControllerGetSplittedPdfUrlQuery } from "src/app/services/api";
import { PdfSvg } from "src/components/atoms/svg/PdfSvg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { downloadUrl } from "src/utils/download";
import { DownloadSvg } from "src/components/atoms/svg/DownloadSvg";
import { ShareSvg } from "src/components/atoms/svg/ShareSvg";
import { humanFileSize } from "src/utils/humanReadableFileSizeUtils";
import { BORDER_RADIUS } from "src/configs/theme";

export const DownloadItem: FC<{ splitPdf: SplitPdfRo }> = ({ splitPdf }) => {
  const { t } = useTranslation();
  const [getDownloadLink] = useLazyTasksControllerGetSplittedPdfUrlQuery();

  const onClickShareButton = async () => {
    const { url } = await getDownloadLink({ id: splitPdf.id }).unwrap();
    navigator.clipboard.writeText(url);
    toast.success(t("copy.success"));
  };

  const onClickDownloadButton = async () => {
    const { url } = await getDownloadLink({ id: splitPdf.id }).unwrap();
    downloadUrl(url);
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={4}
      sx={{
        p: 1,
        px: { xs: 1, md: 2, lg: 3 },
        boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
        borderRadius: BORDER_RADIUS[1],
      }}>
      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
        sx={{
          maxWidth: "70%",
        }}>
        <PdfSvg />
        <Tooltip title={splitPdf.name}>
          <Typography
            noWrap
            textOverflow='ellipsis'
            overflow='hidden'
            maxWidth={{ xs: 100, sm: 150, md: 200, lg: 220 }}
            fontSize={{ md: 20 }}>
            {splitPdf.name}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack direction='row' alignItems='center' columnGap={4}>
        <Typography fontSize={{ md: 18 }}>
          {humanFileSize(splitPdf.fileSize)}
        </Typography>
        <Stack direction='row' alignItems='center' columnGap={0.7}>
          <IconButton onClick={onClickShareButton}>
            <ShareSvg
              sx={{
                "&>path": {
                  fill: ({ palette }) => palette.primary.main,
                },
              }}
            />
          </IconButton>
          <IconButton onClick={onClickDownloadButton}>
            <DownloadSvg color='primary' />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
