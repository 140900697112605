import { ChangeEvent, FC, MouseEventHandler, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useTasksControllerRenameMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";

const removeFileExtension = (name: string) =>
  name.slice(-4) === ".pdf" ? name.slice(0, -4) : name;

type RenameFileDialogPropsType = {
  taskId: string;
  oldName: string;
  dialogProp: DialogProps;
};

export const RenameFileDialog: FC<RenameFileDialogPropsType> = ({
  taskId,
  oldName,
  dialogProp,
}) => {
  const [newName, setNewName] = useState(removeFileExtension(oldName));
  const { t } = useTranslation();

  const [renameTask, { isLoading: renameLoading }] =
    useTasksControllerRenameMutation();

  const inputChangeHandler = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setNewName(value);

  const cancelHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (dialogProp.onClose) {
      dialogProp.onClose(e, "escapeKeyDown");
    }
  };

  const submitHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!newName || newName === removeFileExtension(oldName)) return;

    renameTask({
      splitTaskRenameDto: {
        taskId,
        newName,
      },
    })
      .unwrap()
      .then(() => {
        toast.success(t("rename.success"));
        dialogProp.onClose && dialogProp.onClose(e, "escapeKeyDown");
      });
  };

  return (
    <Dialog
      {...dialogProp}
      maxWidth="sm"
      PaperProps={{ sx: { ...dialogProp.PaperProps?.sx, width: "100%" } }}
    >
      <DialogTitle align="center">{t("pleaseEnterNewName")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          size="small"
          sx={{ mt: 2 }}
          required
          label={t("newName")}
          value={newName}
          onChange={inputChangeHandler}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <LoadingButton
          fullWidth
          loading={renameLoading}
          disabled={!newName || newName === removeFileExtension(oldName)}
          onClick={submitHandler}
          variant="contained"
        >
          {t("submit")}
        </LoadingButton>
        <Button fullWidth variant="outlined" onClick={cancelHandler}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
