import type { FC, MouseEvent } from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { tableOrderType } from "./AdminPanelTable";
import { UserRo } from "src/app/services/api.generated";

type HeadCell = {
  id: keyof UserRo;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type AdminPanelTableHeaderPropsType = {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof UserRo) => void;
  order: tableOrderType;
  orderBy: string;
};

export const AdminPanelTableHeader: FC<AdminPanelTableHeaderPropsType> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: keyof UserRo) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: t("username"),
    },
    {
      id: "isActive",
      numeric: false,
      disablePadding: false,
      label: t("status"),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("name"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("email"),
    },
    {
      id: "lastLogin",
      numeric: false,
      disablePadding: false,
      label: t("activity"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">
          <TableSortLabel disabled></TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
