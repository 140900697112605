import { FC, useCallback, useContext, useEffect } from "react";
import { Stack, Typography, IconButton, Modal } from "@mui/material";
import { Page } from "react-pdf";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";

type SinglePagePropsType = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  handleClose: () => void;
};

export const SinglePage: FC<SinglePagePropsType> = ({
  pageNumber,
  setPageNumber,
  handleClose,
}) => {
  const { numberOfPages } = useContext(UploadFileContext);

  const goNextPage = () => setPageNumber(pageNumber + 1);
  const goPreviousPage = () => setPageNumber(pageNumber - 1);

  const onKeyPress = useCallback(
    ({ keyCode }: any) => {
      keyCode === 37 && setPageNumber(pageNumber - 1);
      keyCode === 39 && setPageNumber(pageNumber + 1);
    },
    [pageNumber, setPageNumber]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyPress);
  }, [onKeyPress]);

  return (
    <Modal open={true} onClose={handleClose}>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "100vh",
          maxWidth: "100vw",
          "& canvas": { maxHeight: "80vh !important" },
        }}
      >
        <Page
          pageNumber={pageNumber + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          loading={<Typography align="center">Loading...</Typography>}
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{
            borderRadius: "4px",
            background: "#999999dd",
            px: 3,
            py: 0.5,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton disabled={pageNumber <= 0} onClick={goPreviousPage}>
              <ArrowBackIosNewIcon sx={{ color: "white" }} />
            </IconButton>
            <Typography fontWeight={700} fontSize={20} color="white">
              {pageNumber + 1} / {numberOfPages}
            </Typography>
            <IconButton
              disabled={pageNumber >= numberOfPages - 1}
              onClick={goNextPage}
            >
              <ArrowForwardIosIcon sx={{ color: "white" }} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
