import type { FC } from "react";
import { useState } from "react";
import {
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { tableOrderType } from "./AdminPanelTable";
import {
  MoreHoriz as MoreHorizIcon,
  TaskAlt as TaskAltIcon,
} from "@mui/icons-material";
import moment from "moment";
import { getComparator } from "src/utils/sortTasks";
import { BinSvg } from "src/components/atoms/svg/BinSvg";
import {
  UserRo,
  useUsersControllerActivateMutation,
  useUsersControllerDeleteMutation,
  useUsersControllerSuspendMutation,
} from "src/app/services/api.generated";
import { stringAvatar } from "src/utils/avatar";
import { HistorySvg } from "src/components/atoms/svg/HistorySvg";
import { EditSvg } from "src/components/atoms/svg/EditSvg";
import { SuspendSvg } from "src/components/atoms/svg/SuspendSvg";
import TableSkeletonRow from "../../tableSkeleton/TableSkeletonRow";
import { toast } from "react-toastify";
import { useAppDispatch } from "src/app/reduxCustomHooks";
import { setSelectedUserAction } from "src/app/slices/appSettingsSlice";
import { useNavigate } from "react-router";
import { ConfirmDialog } from "../../confirmDialog/ConfirmDialog";

type popoverItemType = {
  title: string;
  icon: JSX.Element;
  onClick?: () => any;
  isLoading?: boolean;
  disabled?: boolean;
};

type AdminPanelTableBodyPropsType = {
  rows: UserRo[];
  order: tableOrderType;
  orderBy: keyof UserRo;
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
};

export const AdminPanelTableBody: FC<AdminPanelTableBodyPropsType> = ({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    anchor: HTMLButtonElement;
    index: number;
  } | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const openConfirmDialog = () => setShowConfirmDialog(true);
  const closeConfirmDialog = () => setShowConfirmDialog(false);

  const { t } = useTranslation(),
    dispatch = useAppDispatch();

  const [suspendUser, { isLoading: suspendUserLoading }] =
    useUsersControllerSuspendMutation();

  const [activeUser, { isLoading: activeUserLoading }] =
    useUsersControllerActivateMutation();

  const [deleteUser, { isLoading: deleteUserLoading }] =
      useUsersControllerDeleteMutation(),
    navigate = useNavigate();

  const closePopover = () => setAnchorEl(null);

  const openPopover = ({
    index,
    anchor,
  }: {
    index: number;
    anchor: HTMLButtonElement;
  }) => setAnchorEl({ anchor, index });

  const deleteUserHandler = () => {
    if (!anchorEl) return;
    deleteUser({ usernameDto: { username: rows[anchorEl.index].username } })
      .unwrap()
      .then(() => toast.success(t("user.delete.success")))
      .finally(() => {
        closePopover();
        setShowConfirmDialog(false);
      });
  };

  const suspendUserHandler = () => {
    if (!anchorEl) return;
    suspendUser({ usernameDto: { username: rows[anchorEl.index].username } })
      .unwrap()
      .then((res) => res && toast.success(t("userSuspend")))
      .finally(() => closePopover());
  };

  const activeUserHandler = () => {
    if (!anchorEl) return;
    activeUser({ usernameDto: { username: rows[anchorEl.index].username } })
      .unwrap()
      .then((res) => res && toast.success(t("userActivate")))
      .finally(() => closePopover());
  };

  const popoverListItems = () => {
    let result: popoverItemType[] = [];
    if (!anchorEl) return result;
    const showSuspendActiveItem = rows[anchorEl.index].isActive
      ? {
          title: t("suspend"),
          icon: <SuspendSvg sx={{ fontSize: 16 }} />,
          onClick: suspendUserHandler,
          isLoading: suspendUserLoading,
        }
      : {
          title: t("active"),
          icon: (
            <TaskAltIcon
              sx={{
                ml: -0.2,
                fontSize: 20,
                "&:path": {
                  fill: ({ palette }) => palette.customColor.color11,
                },
                opacity: 0.7,
              }}
            />
          ),
          onClick: activeUserHandler,
          isLoading: activeUserLoading,
        };

    result = [
      {
        title: t("history"),
        icon: (
          <HistorySvg
            sx={{
              fontSize: 16,
              "& > path": {
                fill: ({ palette }) => palette.customColor.color11,
              },
            }}
          />
        ),
        onClick: () => {
          dispatch(setSelectedUserAction(rows[anchorEl.index]));
          navigate("/history");
        },
        isLoading: false,
        disabled: true,
      },
      {
        title: t("edit"),
        icon: (
          <EditSvg
            sx={{
              fontSize: 15,
              "& > g": {
                "& > path": {
                  fill: ({ palette }) => palette.customColor.color11,
                },
              },
            }}
          />
        ),
        onClick: () => {
          dispatch(setSelectedUserAction(rows[anchorEl.index]));
          navigate("/addUser");
        },
        isLoading: false,
      },
      showSuspendActiveItem,
      {
        title: t("remove"),
        icon: (
          <BinSvg
            sx={{
              fontSize: 16,
              "& > path": {
                fill: ({ palette }) => palette.customColor.color11,
              },
            }}
          />
        ),
        onClick: openConfirmDialog,
        isLoading: false,
      },
    ];
    return result;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <TableBody>
        {isLoading ? (
          <TableSkeletonRow cellCount={6} />
        ) : (
          (rows as any)
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: UserRo, index: number) => {
              const realIndex = page * rowsPerPage + index;
              const { username, isActive, name, email, lastLogin } = row;

              return (
                <TableRow hover tabIndex={-1} key={realIndex}>
                  <TableCell align="center" padding="none">
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1.5}
                      sx={{ px: 2, overflow: "hidden" }}
                    >
                      <Avatar
                        sx={{
                          width: 36,
                          height: 36,
                          fontSize: 16,
                          ...stringAvatar(name).sx,
                        }}
                        children={stringAvatar(name).children}
                      />
                      {username}
                    </Stack>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: isActive ? green[500] : red[500],
                      fontWeight: isActive ? 500 : undefined,
                    }}
                  >
                    {t(isActive ? "active" : "suspend")}
                  </TableCell>
                  <TableCell align="center">{name}</TableCell>
                  <TableCell align="center">{email}</TableCell>
                  <TableCell align="center">
                    {moment(lastLogin).fromNow()}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-describedby="more"
                      onClick={(e) =>
                        openPopover({
                          index: realIndex,
                          anchor: e.currentTarget,
                        })
                      }
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Popover
                      elevation={1}
                      id="more"
                      open={anchorEl?.index === index}
                      anchorEl={
                        anchorEl?.index === index ? anchorEl?.anchor : undefined
                      }
                      onClose={closePopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{ p: 2 }}
                      PaperProps={{
                        sx: {
                          borderRadius: 0,
                          boxShadow: "0 4px 6px 0 rgba(0,0,0,0.15)",
                          py: 1.5,
                        },
                      }}
                    >
                      <List>
                        {popoverListItems().map(
                          (
                            { onClick, icon, title, isLoading, disabled },
                            idx
                          ) => (
                            <ListItemButton
                              onClick={onClick}
                              key={idx}
                              sx={{ px: 3.1, py: 1.3 }}
                              disabled={disabled}
                            >
                              {isLoading ? (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{
                                    width: "100%",
                                    minWidth: 92.9,
                                    py: 0.5,
                                  }}
                                >
                                  <CircularProgress size={21} />
                                </Stack>
                              ) : (
                                <>
                                  <ListItemIcon sx={{ minWidth: 30 }}>
                                    {icon}
                                  </ListItemIcon>
                                  <ListItemText primary={title} />
                                </>
                              )}
                            </ListItemButton>
                          )
                        )}
                      </List>
                    </Popover>
                  </TableCell>
                </TableRow>
              );
            })
        )}
        {emptyRows > 0 && (
          <TableRow>
            <TableCell colSpan={7} />
          </TableRow>
        )}
      </TableBody>
      <ConfirmDialog
        dialogProp={{
          open: showConfirmDialog,
          onClose: closeConfirmDialog,
        }}
        onConfirm={deleteUserHandler}
        loading={deleteUserLoading}
      />
    </>
  );
};
