import { FC, useContext } from "react";
import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import {
  useTasksControllerDeleteMutation,
  useTasksControllerTaskPaginationQuery,
} from "src/app/services/api.generated";
import { useLazyTasksControllerZipSplitTasksQuery } from "src/app/services/api";
import { CheckedSvg } from "src/components/atoms/svg/CheckedSvg";
import { useTranslation } from "react-i18next";
import { BORDER_RADIUS } from "src/configs/theme";
import { DownloadSvg } from "src/components/atoms/svg/DownloadSvg";
import { ShareSvg } from "src/components/atoms/svg/ShareSvg";
import { EditSvg } from "src/components/atoms/svg/EditSvg";
import { BinSvg } from "src/components/atoms/svg/BinSvg";
import { HistoryTable } from "../history/HistoryTable";
import { toast } from "react-toastify";
import { DownloadItem } from "../download/DownloadItem";
import { downloadUrl } from "src/utils/download";

const iconsSx: SxProps<Theme> = {
  width: { xs: 15, sm: 20, md: 25 },
  height: { xs: 15, sm: 20, md: 25 },
};

type DownloadPropsType = {};

export const Download: FC<DownloadPropsType> = () => {
  const { t } = useTranslation();

  const { setAppStep, resetApp, task } = useContext(UploadFileContext);

  const { data: tasks, isLoading: getTasksLoading } =
    useTasksControllerTaskPaginationQuery(
      { limit: 5, offset: 0 },
      { refetchOnMountOrArgChange: true }
    );

  const [getZipFile, { isLoading: getZipFileLoading }] =
    useLazyTasksControllerZipSplitTasksQuery();

  const onClickZipButton = async () => {
    if (!task) return;
    const { url } = await getZipFile({ id: task.id }).unwrap();
    downloadUrl(url);
  };

  const [deleteFile, { isLoading: deleteFileLoading }] =
    useTasksControllerDeleteMutation();

  const buttonsArray = [
    {
      onClick: async () => {
        if (!task) return;
        const { url } = await getZipFile({ id: task.id }).unwrap();
        navigator.clipboard.writeText(url);
        toast.success(t("copy.success"));
      },
      icon: getZipFileLoading ? (
        <CircularProgress
          size={20}
          sx={{ "&>svg": { "&>circle": { color: "white" } } }}
        />
      ) : (
        <ShareSvg sx={iconsSx} />
      ),
    },
    {
      onClick: () => {
        if (!task) return;
        deleteFile({ idDto: { id: task.id } })
          .unwrap()
          .then(() => setAppStep(1));
      },
      icon: <EditSvg sx={iconsSx} />,
    },
    {
      onClick: () => {
        if (deleteFileLoading || !task) return;
        deleteFile({ idDto: { id: task.id } })
          .unwrap()
          .then(() => {
            toast.success(t("fileSuccessfullyDeleted"));
            resetApp();
          });
      },
      icon: <BinSvg sx={iconsSx} />,
    },
  ];

  return (
    <Container maxWidth='lg' sx={{ pb: 3 }}>
      <Stack sx={{ py: { xs: 5, sm: 6, md: 7 } }} spacing={3}>
        <Stack direction='row' alignItems='center' spacing={2}>
          <CheckedSvg sx={{ fontSize: { xs: 35, sm: 45, md: 55, lg: 60 } }} />
          <Typography fontSize={{ xs: 24, sm: 30, md: 36 }}>
            {t("split.success")}
          </Typography>
        </Stack>
        <Box>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} sm={8} md={7} lg={6}>
              <Stack
                rowGap={1}
                sx={{
                  px: { xs: 0, md: 5, lg: 6 },
                  py: { xs: 0, md: 3, lg: 4 },
                  boxShadow: { xs: "none", md: "0 0 6px 0 rgba(0,0,0,0.15)" },
                  borderRadius: BORDER_RADIUS[3],
                }}>
                {task?.splitPdfs.map((splitPdf) => (
                  <DownloadItem key={splitPdf.id} splitPdf={splitPdf} />
                ))}
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ mt: { xs: 3, sm: 4, md: 5, lg: 6 } }}>
                  <Button
                    variant='contained'
                    startIcon={
                      !getZipFileLoading ? (
                        <DownloadSvg sx={{ "& > path": { fill: "white" } }} />
                      ) : undefined
                    }
                    onClick={onClickZipButton}
                    sx={{
                      fontSize: { md: "20px" },
                      bgcolor: ({ palette }) => palette.customColor.color8,
                      "&:hover": {
                        bgcolor: ({ palette }) => palette.customColor.color8,
                      },
                      minWidth: 150,
                      minHeight: 50,
                    }}>
                    {getZipFileLoading ? (
                      <CircularProgress
                        size={25}
                        sx={{ "&>svg": { "&>circle": { color: "white" } } }}
                      />
                    ) : (
                      t("downloadZip")
                    )}
                  </Button>
                  {buttonsArray.map(({ icon, onClick }, index) => (
                    <IconButton
                      key={index}
                      sx={{
                        bgcolor: ({ palette }) => palette.customColor.color8,
                        width: { xs: 36, sm: 46, md: 56 },
                        height: { xs: 36, sm: 46, md: 56 },
                        "&:hover": {
                          bgcolor: ({ palette }) => palette.customColor.color8,
                        },
                      }}
                      color='primary'
                      onClick={onClick}>
                      {icon}
                    </IconButton>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              md={4}
              lg={5}
              sx={{ display: { xs: "none", md: "inline-flex" } }}>
              <Box sx={{ p: 2 }}>
                <img
                  src='/images/download_pic.svg'
                  alt='download'
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <HistoryTable
        data={tasks?.tasks || []}
        isLoading={getTasksLoading}
        pagination={false}
        sorting={false}
      />
    </Container>
  );
};
