import type { FC } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { formikOnSubmitType } from "src/types/formTypes";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ProfileSvg } from "src/components/atoms/svg/ProfileSvg";
import { useAppSelector } from "src/app/reduxCustomHooks";
import { EmailSvg } from "src/components/atoms/svg/EmailSvg";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { useUsersControllerSendVerifyEmailMutation } from "src/app/services/api.generated";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

const circularProgress = (isLoading: boolean) =>
  isLoading ? (
    <CircularProgress size={20} disableShrink />
  ) : (
    <ErrorIcon
      sx={{
        "& > path": {
          fill: ({ palette }) => palette.customColor.color8,
        },
      }}
    />
  );

type UserInfoPropsType = {};

let baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
    : process.env.REACT_APP_PRODUCTION_BASE_URL;

export const UserInfo: FC<UserInfoPropsType> = () => {
  const { t } = useTranslation(),
    { pathname } = useLocation();

  const { username, name, email, emailVerified } = useAppSelector((store) => ({
    username: store.auth.user?.username,
    name: store.auth.user?.name,
    email: store.auth.user?.email,
    emailVerified: store.auth.user?.emailVerified,
  }));

  const [sendVerificationEmail, { isLoading: sendVerificationLoading }] =
    useUsersControllerSendVerifyEmailMutation();

  const verificationBtnOnClick = () => {
    if (emailVerified || !email) return;
    sendVerificationEmail({
      mailResetPasswordDto: {
        email: email,
        callbackUrl: baseUrl + pathname,
      },
    })
      .unwrap()
      .then(() => toast.success(t("sendVerificationEmail")));
  };

  const frequentValidation = yup
    .string()
    .min(3, t("tooShort"))
    .max(50, t("tooLong"));

  const formValidationSchema = yup.object().shape({
    username: frequentValidation,
    name: frequentValidation,
  });

  const formInitializer = {
    username,
    name,
  };

  const formSubmitHandler: formikOnSubmitType<typeof formInitializer> = (
    { username, name },
    { setSubmitting }
  ) => {
    if (!username || !name) return;
    setSubmitting(false);
  };

  const emailEndAdornment = (
    <InputAdornment position="end">
      {emailVerified ? (
        <>
          <Typography color="customColor.color12">{t("verified")}</Typography>
          &nbsp;
          <CheckCircleIcon
            sx={{
              "& > path": {
                fill: ({ palette }) => palette.customColor.color12,
              },
            }}
          />
        </>
      ) : (
        <>
          <Button
            onClick={verificationBtnOnClick}
            sx={{
              color: "customColor.color8",
              display: { xs: "none", lg: "inline-flex" },
            }}
            endIcon={circularProgress(sendVerificationLoading)}
            disabled={sendVerificationLoading}
          >
            {t("needVerification")}
          </Button>
          <IconButton
            onClick={verificationBtnOnClick}
            sx={{
              display: { xs: "inline-flex", lg: "none" },
            }}
            disabled={sendVerificationLoading}
          >
            {circularProgress(sendVerificationLoading)}
          </IconButton>
        </>
      )}
    </InputAdornment>
  );

  return (
    <Formik
      initialValues={formInitializer}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="off">
          <Stack rowGap={3} sx={{ p: { xs: 3, sm: 4 } }}>
            <Typography fontSize={20} fontWeight={700}>
              {t("changeInfo")}
            </Typography>
            <TextField
              disabled
              size="small"
              label={t("email")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailSvg fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: emailEndAdornment,
              }}
              value={email}
            />
            <TextField
              size="small"
              label={t("username")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ProfileSvg fontSize="small" />
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors["username"] && touched["username"])}
              helperText={errors["username"]}
              {...getFieldProps("username")}
            />
            <TextField
              size="small"
              label={t("name")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ProfileSvg fontSize="small" />
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors["name"] && touched["name"])}
              helperText={errors["name"]}
              {...getFieldProps("name")}
            />
            <LoadingButton
              // loading={}
              size="small"
              variant="contained"
              type="submit"
              sx={{ width: { xs: "100%", sm: 230 } }}
            >
              <Typography fontSize="20px" color="white" fontWeight={700}>
                {t("saveChange")}
              </Typography>
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
