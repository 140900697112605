import type { FC } from "react";
import { useState } from "react";
import {
  Drawer,
  ListItemText,
  List,
  useMediaQuery,
  useTheme,
  ListItem,
  ListItemIcon,
  Stack,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/reduxCustomHooks";
import { settingsSidebarWidth } from "src/components/templates/SettingsTemplate";
import { AdminPanelSvg } from "src/components/atoms/svg/AdminPanelSvg";
import { LogoutSvg } from "src/components/atoms/svg/LogoutSvg";
import { logoutAction } from "src/app/slices/authSlice";
import { HistorySvg } from "src/components/atoms/svg/HistorySvg";
import { ProfileSvg } from "src/components/atoms/svg/ProfileSvg";
import CustomListItemButton from "src/components/atoms/CustomListItemButton";
import { toast } from "react-toastify";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

type SettingsSidebarPropsType = {};

export const SettingsSidebar: FC<SettingsSidebarPropsType> = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const { t } = useTranslation(),
    { pathname } = useLocation(),
    theme = useTheme(),
    isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector((store) => store.auth.isAdmin);

  const itemsArray = [
    {
      icon: (
        <ProfileSvg
          sx={{
            "&>g>path": {
              fill: ({ palette }) =>
                pathname === "/profile" ? "white" : palette.customColor.color11,
            },
          }}
        />
      ),
      label: t("profile"),
      href: "/profile",
      paths: ["profile"],
    },
    {
      icon: (
        <AdminPanelSvg
          sx={{
            "& > path": {
              fill: ({ palette }) =>
                pathname === "/adminPanel" || pathname === "/addUser"
                  ? "white"
                  : palette.customColor.color11,
            },
          }}
        />
      ),
      label: t("adminPanel"),
      href: "/adminPanel",
      paths: ["adminPanel", "addUser"],
    },
    {
      icon: (
        <HistorySvg
          sx={{
            "& > path": {
              fill: ({ palette }) =>
                pathname === "/history" ? "white" : palette.customColor.color11,
            },
          }}
        />
      ),
      label: t("history"),
      href: "/history",
      paths: ["history"],
    },
    {
      icon: (
        <LogoutSvg
          sx={{
            "&>g>path": {
              fill: ({ palette }) => palette.customColor.color11,
            },
          }}
        />
      ),
      label: t("logout"),
      onClick: () => {
        dispatch(logoutAction());
        toast.success(t("youAreSuccessfullyLoggedOut"));
      },
    },
  ];

  const listItems = (
    <List sx={{ width: "100%", maxWidth: settingsSidebarWidth }}>
      {itemsArray.map(({ icon, label, paths, href, onClick }, index) => {
        const isSelected = paths?.includes(pathname.replace("/", ""));
        let result: JSX.Element | null = (
          <ListItem
            key={index}
            onClick={() => onClick && onClick()}
            sx={{ width: "100%", my: { xs: 1, sm: 2, md: 3 } }}
          >
            <CustomListItemButton href={href}>
              <ListItemIcon>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: ({ palette }) =>
                      isSelected ? palette.primary.main : "transparent",
                    borderRadius: "50px",
                  }}
                >
                  {icon}
                </Stack>
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  color: ({ palette }) =>
                    isSelected ? palette.primary.main : undefined,
                  fontWeight: isSelected ? "bold !important" : "normal",
                }}
              />
            </CustomListItemButton>
          </ListItem>
        );
        if (index === 1 && !isAdmin) {
          result = null;
        }
        return result;
      })}
    </List>
  );

  const showSidebarBtn = (
    <IconButton
      sx={{
        py: 2,
        position: "fixed",
        top: "50%",
        left: showSidebar ? settingsSidebarWidth - 20 : -20,
        transform: "translateY(-50%)",
        display: { lg: "none" },
        bgcolor: "white",
        zIndex: 10000,
        "&:hover": { bgcolor: "white" },
      }}
      onClick={() => setShowSidebar((prevState) => !prevState)}
    >
      {showSidebar ? (
        <ArrowBackIos sx={{ ml: 0.5, mr: -0.5 }} />
      ) : (
        <ArrowForwardIos sx={{ ml: 1, mr: -1 }} />
      )}
    </IconButton>
  );

  return (
    <>
      {!showSidebar && showSidebarBtn}
      <Drawer
        onClose={() => setShowSidebar(false)}
        open={showSidebar}
        anchor="left"
        variant={isLg ? "permanent" : "temporary"}
        sx={{
          width: settingsSidebarWidth,
          height: { lg: "calc(100vh - 64px)" },
        }}
        PaperProps={{
          sx: {
            border: { lg: "none !important" },
            width: settingsSidebarWidth,
            mt: { lg: "80px" },
          },
        }}
      >
        {showSidebar && showSidebarBtn}
        {listItems}
      </Drawer>
    </>
  );
};
