import type { FC } from "react";
import { useContext } from "react";
import {
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Page } from "react-pdf";
import { ScissorsSvg } from "src/components/atoms/svg/ScissorsSvg";
import { UploadFileContext } from "src/components/context/UploadFileContext";

type SmallPageType = {
  index: number;
  isLast: boolean;
  isSelected: boolean;
  cutState: boolean;
  color: string;
  render: boolean;
  selectPage: () => void;
  changeCutState: () => void;
  onRenderSuccess: () => void;
};

export const SmallPage: FC<SmallPageType> = ({
  index,
  isLast,
  isSelected,
  cutState,
  color,
  render,
  selectPage,
  changeCutState,
  onRenderSuccess,
}) => {
  const { setShowPage } = useContext(UploadFileContext);

  return (
    <Box className="extractPage-wrapper" sx={{ mb: 4 }}>
      <Stack
        direction="row"
        sx={{ height: "100%", width: "100%" }}
        alignItems="center"
      >
        <Stack
          sx={{ height: "100%", width: "100%", position: "relative" }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              borderRadius: "2px",
              position: "relative",
              backgroundColor: "white",
              boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
              transition: "100ms",
              cursor: "pointer",
              border: isSelected
                ? `1px solid ${color}`
                : "1px solid transparent",
              "&:hover > .eye-icon": {
                opacity: 1,
                visibility: "visible",
              },
              minHeight: 50,
              width: "100%",
            }}
          >
            <IconButton
              sx={{
                p: 0,
                position: "absolute",
                top: 5,
                left: 5,
                zIndex: 1,
              }}
              onClick={() => selectPage()}
            >
              {isSelected ? (
                <CheckCircleIcon sx={{ color }} />
              ) : (
                <RadioButtonUncheckedIcon sx={{ color: "#9fa2b4" }} />
              )}
            </IconButton>
            <Button sx={{ p: 0 }} onClick={() => selectPage()}>
              {render && (
                <Page
                  loading=""
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  scale={0.5}
                  onRenderSuccess={onRenderSuccess}
                />
              )}
            </Button>
            <IconButton
              className="eye-icon"
              sx={{
                transition: "100ms",
                position: "absolute",
                bottom: 5,
                right: 5,
                opacity: 0,
                visibility: "hidden",
                borderRadius: "50px",
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                "&:hover": {
                  backgroundColor: "rgba(255, 183, 43, 0.2)",
                },
              }}
              size="small"
              onClick={() => setShowPage(index)}
            >
              <VisibilityIcon color="primary" sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>

          <Typography
            sx={{
              position: "absolute",
              bottom: -20,
              fontSize: 12,
              left: "50%",
              transform: "translateX(-50%)",
              color: "#403845",
              textAlign: "center",
            }}
          >
            {index + 1}
          </Typography>
        </Stack>
        <Button
          disabled={isLast}
          onClick={() => changeCutState()}
          sx={{
            position: "relative",
            m: 0,
            p: 0,
            minWidth: 33,
            height: "70%",
            cursor: "pointer",
            "&:hover .scissors": {
              opacity: 1,
              visibility: "visible",
            },
            "&:hover .dashed-line": {
              backgroundImage: ({ palette }) =>
                `linear-gradient(${palette.primary.main} 70%, rgba(255,255,255,0) 0%)`,
            },
          }}
        >
          {!isLast && (
            <>
              <Box
                className="dashed-line"
                sx={{
                  position: "absolute",
                  width: "3px",
                  height: "100%",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundImage: ({ palette }) =>
                    cutState
                      ? `linear-gradient(${palette.primary.main} 100%, rgba(255,255,255,0) 0%)`
                      : "linear-gradient(#CECECE 70%, rgba(255,255,255,0) 0%)",
                  backgroundPosition: "right",
                  backgroundSize: "3px 13px",
                  backgroundRepeat: "repeat-y",
                }}
              ></Box>
              <Stack
                alignItems="center"
                justifyContent="center"
                className="scissors"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  opacity: cutState ? 1 : 0,
                  visibility: cutState ? "visible" : "hidden",
                  borderRadius: cutState ? "50%" : 0,
                  bgcolor: cutState ? "primary.main" : "transparent",
                  color: cutState ? "white" : "primary.main",
                  p: cutState ? 0.5 : 0,
                  transition: "100ms",
                }}
              >
                <ScissorsSvg
                  sx={{
                    fontSize: cutState ? 16 : 24,
                  }}
                />
              </Stack>
            </>
          )}
        </Button>
      </Stack>
    </Box>
  );
};
