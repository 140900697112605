import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const RightSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="16"
      height="17"
      viewBox="0 0 16 17"
      sx={{ fill: "none" }}
      {...props}
    >
      <g clipPath="url(#clip0_334_432)">
        <path
          d="M15.7443 7.87838L9.9661 2.10008C9.80116 1.93514 9.58132 1.8446 9.34692 1.8446C9.11225 1.8446 8.89255 1.93527 8.7276 2.10008L8.20299 2.62483C8.03818 2.78951 7.94738 3.00947 7.94738 3.24401C7.94738 3.47841 8.03818 3.70579 8.20299 3.87047L11.5739 7.24879H0.864383C0.381525 7.24879 0 7.6268 0 8.10979V8.85164C0 9.33463 0.381525 9.75075 0.864383 9.75075H11.6121L8.20312 13.1479C8.03831 13.3129 7.94751 13.5269 7.94751 13.7614C7.94751 13.9957 8.03831 14.2128 8.20312 14.3776L8.72773 14.9006C8.89268 15.0656 9.11238 15.1555 9.34705 15.1555C9.58145 15.1555 9.80129 15.0644 9.96623 14.8995L15.7444 9.12129C15.9097 8.95583 16.0007 8.73496 16 8.50016C16.0005 8.26459 15.9097 8.04358 15.7443 7.87838Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_334_432">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
