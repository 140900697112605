import { FC, useEffect } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import {
  useUsersControllerCreateMutation,
  useUsersControllerUpdateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { formikOnSubmitType } from "src/types/formTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { EmailSvg } from "src/components/atoms/svg/EmailSvg";
import { ProfileSvg } from "src/components/atoms/svg/ProfileSvg";
import { IdCardSvg } from "src/components/atoms/svg/IdCardSvg";
import { LoginLoadingBtn } from "src/components/atoms/LoginLoadingBtn";
import { Link as NextLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/reduxCustomHooks";
import { setSelectedUserAction } from "src/app/slices/appSettingsSlice";

type AddUserFormPropsType = {};

export const AddUserForm: FC<AddUserFormPropsType> = () => {
  const selectedUser = useAppSelector(
    (state) => state.appSettings.selectedUser
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      selectedUser && dispatch(setSelectedUserAction(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation(),
    navigate = useNavigate();

  const [createUser, { isLoading: createUserLoading }] =
    useUsersControllerCreateMutation();

  const [updateUser, { isLoading: updateUserLoading }] =
    useUsersControllerUpdateMutation();

  const frequentValidation = yup
    .string()
    .min(2, t("tooShort"))
    .max(50, t("tooLong"))
    .required(t("required"));

  const formInitialValues = {
    username: selectedUser?.username || "",
    name: selectedUser?.name || "",
    email: selectedUser?.email || "",
  };
  const formValidation = yup.object().shape({
    username: frequentValidation,
    name: frequentValidation,
    email: yup.string().email().required(t("thisSectionIsRequired")),
  });
  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { username, name, email },
    { setSubmitting }
  ) => {
    if (selectedUser) {
      updateUser({
        adminUserUpdateDto: {
          username,
          name,
          email,
        },
      })
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success(t("updateUser.success"));
            navigate("/adminPanel");
          }
        });
    } else {
      createUser({
        userCreateDto: {
          username,
          name,
          email,
          password: "Sajad123",
          isActive: true,
        },
      })
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success(t("addUser.success"));
            navigate("/adminPanel");
          }
        });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidation}
      onSubmit={submitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="on">
          <Stack rowGap={2}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IdCardSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "username",
              }}
              label={t("username") + " *"}
              autoFocus
              error={Boolean(errors.username && touched.username)}
              helperText={errors.username}
              {...getFieldProps("username")}
            />
            <TextField
              size="small"
              fullWidth
              label={t("name") + " *"}
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "name",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ProfileSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.name && touched.name)}
              helperText={errors.name}
              {...getFieldProps("name")}
            />
            <TextField
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailSvg sx={{ width: 16, height: 16 }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "email",
              }}
              label={t("email") + " *"}
              error={Boolean(errors.email && touched.email)}
              helperText={errors.email}
              {...getFieldProps("email")}
            />
            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap={2}
              alignItems="center"
            >
              <LoginLoadingBtn
                loading={createUserLoading || updateUserLoading}
                text={t("submit")}
              />
              <NextLink
                to="/adminPanel"
                style={{ width: "100%", textDecoration: "none" }}
              >
                <Button
                  LinkComponent={NextLink}
                  size="small"
                  fullWidth
                  variant="outlined"
                  sx={{
                    color: ({ palette }) => palette.customColor.color11,
                    fontSize: "20px !important",
                  }}
                >
                  {t("cancel")}
                </Button>
              </NextLink>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
