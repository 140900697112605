import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ShareSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      sx={{ fill: "none", ...props.sx }}
    >
      <path
        d="M19.0312 15.5625C17.6418 15.5625 16.4175 16.246 15.6484 17.2854L8.99953 13.8808C9.10992 13.5045 9.1875 13.1146 9.1875 12.7031C9.1875 12.145 9.07308 11.6145 8.87541 11.1264L15.8338 6.93919C16.6081 7.848 17.7464 8.4375 19.0312 8.4375C21.3576 8.4375 23.25 6.54511 23.25 4.21875C23.25 1.89239 21.3576 0 19.0312 0C16.7049 0 14.8125 1.89239 14.8125 4.21875C14.8125 4.75486 14.9229 5.26341 15.106 5.73553L8.12695 9.93506C7.35323 9.05325 6.23142 8.48438 4.96875 8.48438C2.64239 8.48438 0.75 10.3768 0.75 12.7031C0.75 15.0295 2.64239 16.9219 4.96875 16.9219C6.38109 16.9219 7.62614 16.2183 8.39227 15.1497L15.0193 18.5432C14.8972 18.9373 14.8125 19.3477 14.8125 19.7812C14.8125 22.1076 16.7049 24 19.0312 24C21.3576 24 23.25 22.1076 23.25 19.7812C23.25 17.4549 21.3576 15.5625 19.0312 15.5625Z"
        fill="white"
      />
    </SvgIcon>
  );
};
