import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowDownSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="14"
      height="8"
      viewBox="0 0 14 8"
      sx={{
        fill: "none",
        width: "14px !important",
        height: "14px !important",
      }}
      {...props}
    >
      <path
        d="M6.99676 6.47066L13.0805 0.386889C13.2909 0.176567 13.6319 0.176567 13.8423 0.386889C14.0526 0.597274 14.0526 0.938278 13.8423 1.14863L7.37763 7.61328C7.16728 7.82357 6.82624 7.82357 6.61589 7.61328L0.151242 1.14863C-0.0554495 0.934617 -0.0495148 0.59358 0.1645 0.386889C0.373276 0.185278 0.704242 0.185278 0.912986 0.386889L6.99676 6.47066Z"
        fill="white"
      />
    </SvgIcon>
  );
};
