import type { FC, MouseEvent } from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { tableOrderType } from "./HistoryTable";
import { TaskRo } from "src/app/services/api.generated";
// import { StyledTableRow } from "src/components/atoms/StyledTableRow";
// import { StyledTableCell } from "src/components/atoms/StyledTableCell";

type HeadCell = {
  id: keyof TaskRo;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type HistoryTableHeaderPropsType = {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof TaskRo) => void;
  order: tableOrderType;
  orderBy: string;
  sorting?: boolean;
};

export const HistoryTableHeader: FC<HistoryTableHeaderPropsType> = ({
  order,
  orderBy,
  onRequestSort,
  sorting = true,
}) => {
  const createSortHandler =
    (property: keyof TaskRo) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("name"),
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: t("created"),
    },
    // {
    //   id: "zipSize",
    //   numeric: false,
    //   disablePadding: false,
    //   label: t("size"),
    // },
    {
      id: "taskState",
      numeric: false,
      disablePadding: false,
      label: t("status"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell align='center' />
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align='left'
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            {sorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell align='center'>
          <TableSortLabel disabled></TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
