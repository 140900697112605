import type { FC, MouseEvent } from "react";
import { useState } from "react";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { passwordValidationRegex } from "src/utils/regexUtils";
import { Form, Formik } from "formik";
import { formikOnSubmitType } from "src/types/formTypes";
import { useUsersControllerChangePasswordMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { LockSvg } from "src/components/atoms/svg/LockSvg";

type inputsTypes = "currentPassword" | "newPassword" | "newPasswordConfirm";

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidationRegex.test(value);

type ChangePasswordPropsType = {};

export const ChangePassword: FC<ChangePasswordPropsType> = () => {
  const [showPassword, setShowPassword] = useState([false, false, false]);

  const { t } = useTranslation();

  const [changePassword, { isLoading: changeLoading }] =
    useUsersControllerChangePasswordMutation();

  const showPasswordHandler = (index: number) => {
    setShowPassword((prevState) => {
      let result = [...prevState];
      result[index] = !prevState[index];
      return result;
    });
  };
  
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const passwordValidation = yup
    .string()
    .test(t("passwordValidation"), t("changePassword.hint"), (value) =>
      passValidationHandler(value as string)
    )
    .required(t("thisSectionIsRequired"));

  const formValidationSchema = yup.object({
    currentPassword: passwordValidation,
    newPassword: passwordValidation,
    newPasswordConfirm: yup
      .string()
      .oneOf([yup.ref("newPassword"), undefined], t("changePassword.repeat"))
      .required(t("thisSectionIsRequired")),
  });

  const formInitializer = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formSubmitHandler: formikOnSubmitType<typeof formInitializer> = (
    { newPassword },
    { setSubmitting, resetForm }
  ) => {
    changePassword({ changePasswordDto: { password: newPassword } })
      .unwrap()
      .then(() => {
        toast.success(t("updatePassword"));
      });
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={formInitializer}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="off">
          <Stack rowGap={3} sx={{ p: { xs: 3, sm: 4 } }}>
            <Typography fontSize={20} fontWeight={700}>
              {t("changePassword")}
            </Typography>
            {["currentPassword", "newPassword", "newPasswordConfirm"].map(
              (item: any, index) => (
                <TextField
                  key={index}
                  size="small"
                  label={t(item) + " *"}
                  fullWidth
                  type={showPassword[index] ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => showPasswordHandler(index)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword[index] ? (
                            <Visibility
                              sx={{
                                fill: ({ palette }) =>
                                  palette.customColor.color3,
                                opacity: 0.5,
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                fill: ({ palette }) =>
                                  palette.customColor.color3,
                                opacity: 0.5,
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <LockSvg fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    errors[item as inputsTypes] && touched[item as inputsTypes]
                  )}
                  helperText={errors[item as inputsTypes]}
                  {...getFieldProps(item as inputsTypes)}
                />
              )
            )}
            <LoadingButton
              loading={changeLoading}
              size="small"
              variant="contained"
              type="submit"
              sx={{ width: { xs: "100%", sm: 230 } }}
            >
              <Typography fontSize="20px" color="white" fontWeight={700}>
                {t("saveChange")}
              </Typography>
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
