import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DropBoxSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="18"
      height="14"
      viewBox="0 0 18 14"
      sx={{
        fill: "none",
      }}
      {...props}
    >
      <path
        d="M9 8.73047L4.98047 11.2227L9 13.7148L13.0195 11.2227L9 8.73047Z"
        fill="#1976D2"
      />
      <path
        d="M13.0195 5.26953L9 7.75781L13.0195 10.25L17.0391 7.75781L13.0195 5.26953Z"
        fill="#1976D2"
      />
      <path
        d="M4.98047 5.26953L0.960938 7.75781L4.98047 10.25L9 7.75781L4.98047 5.26953Z"
        fill="#1976D2"
      />
      <path
        d="M13.0195 0.285156L9 2.77734L13.0195 5.26953L17.0391 2.77734L13.0195 0.285156Z"
        fill="#1976D2"
      />
      <path
        d="M4.98047 0.285156L0.960938 2.77734L4.98047 5.26953L9 2.77734L4.98047 0.285156Z"
        fill="#1976D2"
      />
    </SvgIcon>
  );
};
