import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ScissorsSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M20.1681 18.5488C19.8879 17.2056 18.8414 16.1198 17.5013 15.7823C16.5846 15.5522 15.6634 15.6682 14.856 16.109L13.8216 13.7704L17.7797 4.27176C17.8988 3.98577 17.9588 3.68452 17.9588 3.37614C17.9588 2.59601 17.5712 1.87183 16.9221 1.43901L16.3796 1.07735C16.2668 1.00173 16.1263 0.980244 15.9974 1.0187C15.8676 1.0563 15.7611 1.14941 15.7064 1.27337L12.0004 9.65269L8.29416 1.27341C8.23955 1.14945 8.13303 1.05634 8.00322 1.01874C7.87342 0.980244 7.73287 1.00173 7.62098 1.07739L7.07851 1.43906C6.42948 1.87183 6.04186 2.59601 6.04186 3.37614C6.04186 3.68452 6.10185 3.98573 6.22091 4.27176L10.1789 13.7713L9.14372 16.1094C8.61109 15.8185 8.02295 15.6668 7.41688 15.6668C5.39464 15.6668 3.75012 17.3116 3.75012 19.3334C3.75012 21.3551 5.39459 23 7.41688 23C9.43913 23 11.0836 21.3551 11.0836 19.3334C11.0836 17.9776 11.5273 16.9864 11.9997 16.3088C12.459 16.9661 12.8879 17.9073 12.9152 19.2027C12.9582 21.2302 14.4845 22.8957 16.3886 22.995C16.4549 22.9982 16.5202 23 16.5847 23C17.6598 23 18.666 22.539 19.3696 21.7176C20.1162 20.8462 20.4071 19.691 20.1681 18.5488ZM7.41675 21.1667C6.4061 21.1667 5.58344 20.3445 5.58344 19.3334C5.58344 18.3223 6.4061 17.5001 7.41675 17.5001C8.4274 17.5001 9.25007 18.3223 9.25007 19.3334C9.25007 20.3445 8.4274 21.1667 7.41675 21.1667ZM12 14.7502C11.4942 14.7502 11.0833 14.3388 11.0833 13.8335C11.0833 13.3282 11.4942 12.9169 12 12.9169C12.5058 12.9169 12.9166 13.3282 12.9166 13.8335C12.9166 14.3388 12.5058 14.7502 12 14.7502ZM16.5832 21.1667C15.5726 21.1667 14.7499 20.3445 14.7499 19.3334C14.7499 18.3223 15.5726 17.5001 16.5832 17.5001C17.5939 17.5001 18.4166 18.3223 18.4166 19.3334C18.4166 20.3445 17.5939 21.1667 16.5832 21.1667Z' />
    </SvgIcon>
  );
};
