import type { FC } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

type LoginLoadingBtnPropsType = {
  disabled?: boolean;
  loading: boolean;
  text: string;
};

export const LoginLoadingBtn: FC<LoginLoadingBtnPropsType> = ({
  disabled = false,
  loading,
  text,
}) => {
  return (
    <LoadingButton
      disabled={disabled}
      size="small"
      loading={loading}
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      sx={{
        fontSize: "20px !important",
      }}
      loadingIndicator={<CircularProgress color="inherit" size={20} />}
    >
      {text}
    </LoadingButton>
  );
};
