import sha1 from "crypto-js/sha1";

export const stringToColor = (s: string, lightness = 0.8) => {
  const str = sha1(s).toString();
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    let value = (hash >> (i * 8)) & 0xff;
    value = Math.round(value * lightness);

    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const hexToRgb = (hex: string): number[] | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result && result.length === 4
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const stringAvatar = (name: string) => {
  const nameLength = name.split(" ");
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      nameLength.length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name.split(" ")[0][0]}`,
  };
};
