import type { FC, MouseEventHandler } from "react";
import { useState } from "react";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import i18n from "src/i18n";

export const LanguageSwitcher: FC = () => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();

  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler: MouseEventHandler<HTMLButtonElement> = ({
    currentTarget,
  }) => setAnchorEl((prevState) => (prevState ? null : currentTarget));

  const open = Boolean(anchorEl);
  const id = open ? "choose language" : undefined;

  const langsArray = [
    {
      txt: "English",
      val: "en",
    },
    {
      txt: "Dutch",
      val: "nl",
    },
  ];

  const languageChangeHandler = (lng: string) => {
    i18n.changeLanguage(lng);
    closeMenuHandler();
  };

  return (
    <>
      <Button sx={{ width: "51px", height: "51px" }} onClick={openMenuHandler}>
        <Typography textTransform="uppercase" fontSize="20px">
          {i18n.language}
        </Typography>
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenuHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            backgroundColor: "customGrey.light",
            borderRadius: 0,
            py: 2,
            mt: 1,
            boxShadow: "0 4px 6px rgba(0,0,0,0.16)",
          },
        }}
      >
        {langsArray.map(({ txt, val }, index) => {
          let isSelected = i18n.language === val;
          return (
            <MenuItem
              key={index}
              selected={isSelected}
              onClick={() => languageChangeHandler(val)}
            >
              <ListItemText>
                <Typography
                  align="center"
                  fontSize={14}
                  color={isSelected ? "primary" : undefined}
                >
                  {txt}
                </Typography>
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
