import type { FC } from "react";
import { ListItemButton, ListItemButtonProps, styled } from "@mui/material";
import { Link } from "react-router-dom";

const CustomListItemBtn = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    color: theme.palette.customColor.color3,
    "&.Mui-selected": {
      backgroundColor: "white",
      "& > .MuiListItemText-root": {
        "& > .MuiListItemText-primary": {
          color: theme.palette.primary.main,
        },
      },
      "& > .custom_list_item_btn-icon": {
        "& > path": {
          fill: theme.palette.primary.main,
        },
      },
      "& > .MuiListItemIcon-root": {
        "& > svg": {
          "& > path": {
            stroke: theme.palette.primary.main,
          },
        },
      },
    },
  })
);

type CustomListItemButtonPropsType = {
  listItemButtonProps?: ListItemButtonProps;
  children: any;
  href?: string;
};

const CustomListItemButton: FC<CustomListItemButtonPropsType> = ({
  listItemButtonProps,
  children,
  href,
}) => {
  return href ? (
    <Link to={href} style={{ textDecoration: "none", width: "100%" }}>
      <CustomListItemBtn {...listItemButtonProps}>{children}</CustomListItemBtn>
    </Link>
  ) : (
    <CustomListItemBtn {...listItemButtonProps}>{children}</CustomListItemBtn>
  );
};

export default CustomListItemButton;
