import type { FC } from "react";
import { Dialog, Typography } from "@mui/material";
import loadingGif from "src/assets/loading.gif";
import { useTranslation } from "react-i18next";

const Loading: FC = () => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ width: "100vw", height: "100vh" }}
      open
      PaperProps={{
        sx: {
          boxShadow: "none",
        },
      }}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: "white",
          },
        },
      }}
    >
      <Typography align="center" fontSize={40} fontWeight={700} sx={{ mb: 7 }}>
        {t("Loading")}...
      </Typography>
      <img src={loadingGif} alt="loading gif" />
      <Typography align="center" sx={{ mt: 7 }}>
        {t("pleaseBePatient")}
      </Typography>
      <Typography
        fontSize={16}
        color="customColor.color11"
        sx={{ position: "fixed", bottom: 10, left: 30 }}
      >
        © 2022 Scann PBL
      </Typography>
    </Dialog>
  );
};

export default Loading;
