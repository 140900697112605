import type { FC } from "react";
import { useContext } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Divider,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import { sidebarWidth } from "src/components/templates/MainTemplate";
import { useTranslation } from "react-i18next";
import { SelectAllSvg } from "src/components/atoms/svg/SelectAllSvg";
import { CircularSvg } from "src/components/atoms/svg/CircularSvg";
import { ScissorsSvg } from "src/components/atoms/svg/ScissorsSvg";
import { ZoomExtractPages } from "src/components/atoms/ZoomExtractPages";
import { UploadFileContext } from "src/components/context/UploadFileContext";
// import { AscendingSortSvg } from "src/components/atoms/svg/AscendingSortSvg";
// import { DescendingSortSvg } from "src/components/atoms/svg/DescendingSortSvg";

type ExtractHeaderPropsType = {};

export const ExtractHeader: FC<ExtractHeaderPropsType> = () => {
  const { t } = useTranslation();
  const {
    splitAllPages,
    selectAllPages,
    resetPages,
    //  setSort
  } = useContext(UploadFileContext);

  const iconsArray = [
    // {
    //   icon: <AscendingSortSvg sx={{ color: "#403845" }} />,
    //   title: t("sortAscending"),
    //   onClick: () => setSort("asc"),
    // },
    // {
    //   icon: <DescendingSortSvg sx={{ color: "#403845" }} />,
    //   title: t("sortDescending"),
    //   onClick: () => setSort("desc"),
    // },
    {
      icon: <ScissorsSvg sx={{ color: "#403845" }} />,
      title: t("splitAllPages"),
      onClick: () => splitAllPages(),
    },
    {
      icon: <SelectAllSvg sx={{ color: "#403845" }} />,
      title: t("selectAll"),
      onClick: () => selectAllPages(),
    },
    {
      icon: <CircularSvg sx={{ color: "#403845" }} />,
      title: t("reset"),
      onClick: () => resetPages(),
    },
  ];

  return (
    <AppBar
      sx={{
        mr: { xs: "0", md: sidebarWidth + "px" },
        width: { xs: "100%", md: `calc(100% - ${sidebarWidth}px)` },
        top: { xs: "56px", sm: "64px" },
        bgcolor: "white",
        boxShadow: "0 4px 4px 0 rgba(0,0,0,.05)",
        zIndex: 2,
        maxHeight: "56px",
        overflow: "hidden",
      }}
    >
      <Divider
        sx={{
          top: -9,
          left: 0,
          border: "none",
          width: "100%",
          height: "7px",
          backgroundImage:
            "linear-gradient(to right, #FFB840, #FA9057, #F77268, #A6A581, #6FC792)",
          filter: "blur(10px)",
          position: "absolute",
        }}
      />
      <Container maxWidth={false} sx={{ maxHeight: "56px" }}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: "space-between",
            minHeight: "56px !important",
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" columnGap={2}>
              {iconsArray.map(({ title, icon, onClick }, index) => (
                <Tooltip title={title} onClick={onClick} key={index}>
                  <IconButton>{icon}</IconButton>
                </Tooltip>
              ))}
            </Stack>
            <ZoomExtractPages />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
