import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoIconSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="90"
      height="68"
      viewBox="0 0 90 68"
      sx={{ fill: "none" }}
      {...props}
    >
      <path
        opacity="0.75"
        d="M0 67.6693C24.7648 67.6693 44.8688 52.5834 44.8688 34C44.8688 15.4167 24.7648 0.330811 0 0.330811V16.5651V67.6693Z"
        fill="#3FB56E"
      />
      <path
        opacity="0.75"
        d="M22.4695 67.6693C47.2343 67.6693 67.3383 52.5834 67.3383 34C67.3383 15.4167 47.2343 0.330811 22.4695 0.330811V16.5651V67.6693Z"
        fill="#F44336"
      />
      <path
        opacity="0.75"
        d="M44.8691 67.6693C69.634 67.6693 89.738 52.5834 89.738 34C89.738 15.4167 69.634 0.330811 44.8691 0.330811V16.5651V67.6693Z"
        fill="#FFA000"
      />
    </SvgIcon>
  );
};
