import { FC, useEffect } from "react";
import { useContext } from "react";
import { GetFile } from "src/components/organisms/splitting/getFile/GetFile";
import { Extract } from "src/components/organisms/splitting/Extract";
import { Download } from "src/components/organisms/splitting/Download";
import { UploadFileContext } from "src/components/context/UploadFileContext";
import { ExtractTemplate } from "src/components/templates/ExtractTemplate";
import { useLocation } from "react-router";

export type useLocationStateType =
  | {
      file?: File;
    }
  | undefined;

type MainPropsType = {};

export const Main: FC<MainPropsType> = () => {
  const { appStep, task, resetApp, setFile, setAppStep } =
    useContext(UploadFileContext);

  const state = useLocation().state as useLocationStateType;

  useEffect(() => {
    if (state && state.file) {
      setFile(state.file);
      setAppStep(1);
    }
  }, [setAppStep, setFile, state]);

  switch (appStep) {
    case 0:
      return <GetFile />;
    case 1:
      return (
        <ExtractTemplate>
          <Extract />
        </ExtractTemplate>
      );
    case 2:
      if (task) {
        return <Download />;
      } else {
        resetApp();
        return <></>;
      }
    default:
      return <></>;
  }
};
