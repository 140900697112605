type liveSearchType = {
  inputValue: string;
  originalArray: any[];
  searchItem: string;
};

export const liveSearch = ({
  inputValue,
  originalArray,
  searchItem,
}: liveSearchType) => {
  let result: any[] = [];

  if (inputValue && originalArray.length > 0) {
    [...originalArray].map((item) =>
      inputValue
        .split(" ")
        .map(
          (word) =>
            item[searchItem].indexOf(word) !== -1 &&
            !result.map((resultItem) => resultItem.id).includes(item.id) &&
            result.push(item)
        )
    );
  } else {
    result = [...originalArray];
  }

  return result;
};
