import type { FC } from "react";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, responsiveFontSizes, ThemeProvider } from "@mui/material";
import createEmotionCache from "src/createEmotionCache";
import theme from "src/configs/theme";
import Router from "./routes/Router";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const App: FC = () => {
  return (
    <CacheProvider value={createEmotionCache()}>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <Router />
        <ToastContainer limit={3} />
      </ThemeProvider>
    </CacheProvider>
  );
};
