import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const GoogleDriveSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      sx={{
        fill: "none",
      }}
      {...props}
    >
      <path
        d="M5.10156 16.2227L7.37109 12.2852H17.168L14.9727 16.2227H5.10156Z"
        fill="#2196F3"
      />
      <path
        d="M7.70312 3.77734L10.0156 7.6875L5.10156 16.2227L2.83203 12.2852L7.70312 3.77734Z"
        fill="#3FB56E"
      />
      <path
        d="M12.2422 3.77734L17.168 12.2852H12.6523L7.70312 3.77734H12.2422Z"
        fill="#FFC107"
      />
    </SvgIcon>
  );
};
